import { Component, Input, TemplateRef, ViewChild, Output, OnChanges, OnInit } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { trigger, style, animate, transition } from '@angular/animations';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap';
import { EventEmitter } from '@angular/core';
import { DeviceService } from '../../../services/device/device.service';
import { Device } from '../../../models/device.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-device-details-editor',
    templateUrl: 'deviceEditor.template.html',
    animations: [
        trigger(
            'enterAnimation', [
                transition(':enter', [
                    // css styles at start of transition
                    style({ opacity: 0 }),

                    // animation and styles at end of transition
                    animate('0.5s', style({ opacity: 1 }))
                ]),
            ]
        )
    ],
})
export class DeviceEditorComponent implements OnChanges, OnInit  {

    @Input() device: Device;
    @Output() onDeleted = new EventEmitter();

    @ViewChild('autoShownModal') autoShownModal: ModalDirective;
    modalRef: BsModalRef;
    isModalShown = false;

    mode: FormMode;
    permissions: {};

    constructor(private modalService: BsModalService, private deviceService: DeviceService, private authentication: AuthenticationService) {
        this.mode = 1;
    }

    ngOnInit(): void {
        this.permissions = this.authentication.permissions;
    }

    changeFlagged(event) {
        this.device.isFlagged = !this.device.isFlagged;
        this.deviceService.saveFlagged(this.device).subscribe(result => {
            console.log(result);
        });
    }

    changeIssueCalculation(event) {
        this.device.hasIssueCalculationEnabled = !this.device.hasIssueCalculationEnabled;
        this.deviceService.saveIssueCalculation(this.device).subscribe(result => {
            console.log(result);
        });
    }

    ngOnChanges(): void {
    }

    changeMode(mode: FormMode) {
        this.mode = mode;
    }
    // Model

    showModal(): void {
        this.isModalShown = true;
    }

    deleteDevice(): void {
        this.onDeleted.emit(this.device);
        this.autoShownModal.hide();
    }

    declineDelete(): void {
        this.autoShownModal.hide();
    }

    hideModal(): void {
        this.autoShownModal.hide();
    }

    onHidden(): void {
        this.isModalShown = false;
    }
}
