import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy, APP_BASE_HREF } from '@angular/common';
import { AuthGuard } from './auth-guard';
import { AuthenticationService } from './services/authentication/authentication.service';
import { DashboardService } from './services/dashboard/dashboard.service';
import { LoggingService } from './services/logging/logging.service';
import { ErrorLogService } from './services/logging/error-log.service';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, LoginOpt } from 'angularx-social-login';

// App views
import { AppviewsModule } from './views/appviews.module';

// App modules/components
import { SharedLayoutsModule } from './views/shared/sharedlayouts.module';

import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

import { CookieService } from 'ngx-cookie-service';
import { LoggingErrorHandlerProviders, LoggingErrorHandlerOptions } from './services/logging/logging-error-handler';
import { ColorService } from './services/common/color.service';

// Calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Translation module
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MissingTranslationHandler } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from 'app/common/missingTransLationHandler';

// Chart module
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as Highcharts from 'highcharts';
import * as boostCanvas from 'highcharts/modules/boost-canvas';
import * as boost from 'highcharts/modules/boost.src';
import * as drilldown from 'highcharts/modules/drilldown.src';
import * as overlappingdatalabels from 'highcharts/modules/overlapping-datalabels.src';
import * as solidgauge from 'highcharts/modules/solid-gauge.src';

// Notifications
import { ToastrModule } from 'ngx-toastr';
import { NotificationsModule, NotificationsService } from './modules/notification'

// Modules
import { ICheckModule } from './modules/icheck/icheck.module';
import { WizardModule } from './modules/wizard/wizard.module';

// Export functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function getAuthServiceConfigs() {
  const fbLoginOptions: LoginOpt = {
    scope: 'email',
    return_scopes: true,
    enable_profile_selector: true,
    auth_type: 'rerequest'
  };

  const config = new AuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(environment.SSOFacebookID, fbLoginOptions)
    },
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider('866539143318-8auu61gi3s1koj7ptr1s6m1p7o34s3ou.apps.googleusercontent.com')
    }
  ]);
  return config;

}

import './common/rxjs-operators';
import { TranslatorService } from './services/common/translator.service';
import { environment } from 'environments/environment';
import { UserService } from './services/users/user.service';
import { AccountService } from './services/accounts/accounts.service';
import { InfoService } from './services/info/info.service';
import { DeviceService } from './services/device/device.service';
import { ImportService } from './services/import/import.service';
import { ReportService } from './services/report/report.service';
import { TripService } from './services/trip/trip.service';
import { AuditLogService } from './services/auditlog/auditlog.service';
// todo : https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientJsonpModule,
    SharedLayoutsModule,
    AppviewsModule,
    ChartModule,
    WizardModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ICheckModule.forRoot(),
    RouterModule.forRoot(ROUTES),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(255,255,255,0.7)',
      backdropBorderRadius: '4px',
      primaryColour: '#DC8139',
      secondaryColour: '#DC8139',
      tertiaryColour: '#DC8139'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
    }),
    HttpClientModule,
    NotificationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    SocialLoginModule,
  ],
  providers: [
    ErrorLogService,
    LoggingErrorHandlerProviders,
    {
      provide: LoggingErrorHandlerOptions,
      useValue: {
        rethrowError: false,
        unwrapError: false
      }
    },
    AuthGuard,
    TranslatorService,
    AccountService,
    CookieService,
    UserService,
    AuditLogService,
    DeviceService,
    TripService,
    ImportService,
    ReportService,
    NotificationsService,
    InfoService,
    LoggingService,
    ColorService,
    AuthenticationService,
    DashboardService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [
        more, solidgauge, boostCanvas, boost, overlappingdatalabels, drilldown //  exporting, heatmap, noDataToDisplay, offlineExporting, serieslabel
      ]
    },
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
