export const environment = {
    version: '1.2019.1121.1',
    production: false,
    ConsumerToken: 'c4p8GFRvoWjo+x1I4QZNZP2hpsoRrIvkj44xP5J0zj6eG7fYI1RgNMyXXxNmmWdkjyRjjs4VlWJFMA8OR6huFw==',
    AuthenticationUrl: 'https://fleethealth-api.staging.gpscockpit.net/api/',
    Environment: 'staging',
    Debug: true,
    SSOEnabled: true,
    SSOAuthenticationUrl: 'https://sso.staging.gpscockpit.net',
    SSORedirectUrl: 'https://fleethealth.staging.gpscockpit.net/#/Auth-callback?',
    SSOPostLogoutRedirectUrl: 'https://fleethealth.staging.gpscockpit.net/#/Endsession-callback',
    SSOFacebookID: '570992783334044',
    ToolsUrl: 'https://fleettools-api.staging.gpscockpit.net/api/',
    WialonUrl: 'https://hst-api.wialon.com/wialon/ajax.html?'
};
