import { Component, Input } from '@angular/core';

@Component({
  selector: 'fh-driver-delete-params',
  templateUrl: 'driver-delete-params.template.html',
  providers: []
})

export class DriverDeleteParamsComponent {
  @Input() params: any[];
  @Input() drivers: boolean;
  @Input() headers: string[];

  constructor() { }

  addRow(): void {
    this.params.push({
      key: '',
      value: ''
    });
  }

  removeRow(i: number): void {
    this.params.splice(i, 1);
  }
}
