import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImportService } from 'app/services/import/import.service';

@Component({
  selector: 'fh-passenger-import',
  templateUrl: 'passenger-import.template.html',
  providers: []
})

export class PassengerImportComponent implements OnInit {
  alert: any;
  csvRecords: any[] = [];
  selectedRecords: any[] = [];
  filename: string;
  csvHeaders: string[] = [];
  dtOptions: any;
  duplicates: any;
  missingHeaders: string[] = [];
  wrappers: any[];
  selectedWrapper: string;
  resources: any;
  selectedResource: number;
  tagsGroups: any[];
  selectedTagsGroup: string;
  selTagsGroup: any;
  passengers: any[];
  mappingHeaders: string[];
  allowMappingFields: boolean;
  duration = 7200;
  uniqueValues: any = {};
  openTable = true;
  type = 'add';
  showPassTable: boolean;
  updateParams: any[] = [{
    all: false,
    keyF: '',
    keyV: '',
    replaceF: '',
    replaceV: ''
  }];
  deleteParams: any[] = [{
    key: '',
    value: ''
  }];
  tableHeaders: string[];
  selectKey: boolean;
  updateKey: string;
  combineHeaders: boolean;
  combineHeaderList: any[];
  editedPassengers: number[] = [];
  deletedPassengers: number[] = [];
  savedPassengers: number[] = [];
  duplicateHeaders: boolean;
  platformsFound: boolean;

  constructor(
    private translateService: TranslateService,
    private importService: ImportService
  ) { }

  ngOnInit(): void {
    this.importService.getDrivers();
    this.importService.getPassengersDone().subscribe(result => {
      this.platformsFound = Object.keys(result).length > 0;
      if (this.platformsFound) {
        this.wrappers = Object.keys(result);
        this.selectedWrapper = this.wrappers && this.wrappers.length ? this.wrappers[0] : null;

        this.resources = {};
        Object.keys(result).forEach(item => {
          this.resources[item] = [];
          result[item].forEach(resource => {
            let tagsGroups = [];
            if (resource.tagsgr) {
              Object.keys(resource.tagsgr).forEach(tagsgr => {
                tagsGroups.push(resource.tagsgr[tagsgr]);
              });
            }
            tagsGroups.sort((a: any, b: any) => (a.n > b.n) ? 1 : ((b.n > a.n) ? -1 : 0));
            tagsGroups = [{ id: null, n: this.translateService.instant('import.noTagsGroups'), d: '', tgs: [] }, ...tagsGroups];
            const tags = [];
            if (resource.tags) {
              Object.keys(resource.tags).forEach(tag => {
                resource.tags[tag]['Name'] = resource.tags[tag].n;
                resource.tags[tag]['Code'] = resource.tags[tag].c;
                resource.tags[tag]['ART'] = resource.tags[tag].art;
                tags.push(resource.tags[tag]);
              });
            }
            this.resources[item].push({
              'Name': resource.nm,
              'ReferenceId': resource.id,
              'TagsGroups': tagsGroups,
              'Tags': tags
            });
          });
          this.resources[item].sort((a: any, b: any) => (a['Name'] > b['Name']) ? 1 : ((b['Name'] > a['Name']) ? -1 : 0));
        });
        this.selectedResource = this.selectedWrapper && this.resources[this.selectedWrapper] && this.resources[this.selectedWrapper].length ? this.resources[this.selectedWrapper][0]['Name'] : null;
        // console.log(this.resources);
        this.passengers = this.resources[this.selectedWrapper][0]['Tags'];
        this.setTableHeaders(this.passengers);
        this.tagsGroups = this.selectedResource ? this.resources[this.selectedWrapper][0]['TagsGroups'] : null;
        this.selectedTagsGroup = this.selectedResource ? this.resources[this.selectedWrapper][0]['TagsGroups'][0].n : null;
      } else {
        this.alert = {
          text: this.translateService.instant('alert.noPlatformsFound'),
          type: 'alert-danger'
        };
      }
    });
    this.importService.getPassengers();
    const that = this;
    window.onclick = function (event) {
      if (!$(event.target).attr('class') || !$(event.target).attr('class').includes('dropdown')) {
        that.toggleGroupDropdown('');
      }
    }

    this.importService.addPassengersDone().subscribe(result => {
      if (result.status === 'success') {
        this.savedPassengers = JSON.parse(JSON.stringify(this.editedPassengers));
        this.editedPassengers = [];
        this.alert = {
          text: this.translateService.instant('alert.' + result.type + 'PassengersSuccess'),
          type: 'alert-success'
        };
      } else {
        this.alert = {
          text: this.translateService.instant('alert.addingPassengersFail'),
          type: 'alert-danger'
        };
      }
      setTimeout(() => {
        this.alert = undefined;
      }, 5000);
    });

    this.translateService.get('general.date').subscribe(value => {
      console.log('language loaded');
    });
  }

  getFile(file: any): void {
    this.alert = null;
    this.updateKey = null;
    this.mappingHeaders = [];
    this.savedPassengers = [];
    Object.keys(file).forEach(key => {
      this[key] = file[key];
    });
    this.setDtOptions();
    if (this.type === 'update') {
      this.updateKey = null;
      this.selectKey = true;
    } else if (this.type === 'delete') {
      this.setDeletingPassengers();
    }
  }

  groupData(header: string): void {
    this.openTable = false;
    if (this.uniqueValues[header]) {
      const selectedHeaders = [];
      this.uniqueValues[header].headers.forEach(head => {
        if (head.active) {
          selectedHeaders.push(head.name);
        }
      });
      this.selectedRecords = [];
      this.csvRecords.forEach(record => {
        if (selectedHeaders.indexOf(record[header]) !== -1) {
          this.selectedRecords.push(record);
        }
      });
    }
    setTimeout(() => {
      this.openTable = true;
    }, 1);
  }

  mapHeaders(mappedHeaders: any[]) {
    let refId = null;
    this.resources[this.selectedWrapper].forEach(resource => {
      if (resource['Name'] === this.selectedResource) {
        refId = parseInt(resource['ReferenceId'], 10);
      }
    });
    let correctMappedHeaders = true;
    const passengers = [];
    this.selectedRecords.forEach(record => {
      const passenger = {
        'Code': record['Code'],
        'Name': record['Name']
      }
      mappedHeaders.forEach(header => {
        if (header.key.trim() && header.value.trim()) {
          passenger[header.key] = record[header.value];
        } else {
          correctMappedHeaders = false;
        }
      });

      passengers.push(passenger);
    });
    if (correctMappedHeaders) {
      let tgsGr = {};
      this.tagsGroups.forEach(tagsGroup => {
        if (tagsGroup.n === this.selectedTagsGroup) {
          tgsGr = tagsGroup;
        }
      });
      this.importService.addPassengers(passengers, this.selectedWrapper, refId, this.duration, tgsGr, 'create');
    } else {
      this.alert = {
        text: this.translateService.instant('alert.noHeaderOrKey'),
        type: 'alert-danger'
      };
    }
  }

  toggleGroupDropdown(header: string) {
    Object.keys(this.uniqueValues).forEach(head => {
      this.uniqueValues[head].open = !this.uniqueValues[header] || header !== head ? false : !this.uniqueValues[header].open;
    });
  }

  setDeletingPassengers(): void {
    this.deletedPassengers = [];
    this.passengers.forEach(passenger => {
      this.csvRecords.forEach(record => {
        let delPassenger = true;
        Object.keys(record).forEach(item => {
          const it = ['Name', 'Code'].indexOf(item) !== -1 ? passenger[item] : passenger.jp[item];
          delPassenger = it && record[item] && record[item] === it ? delPassenger : false;
        });
        if (delPassenger && this.deletedPassengers.indexOf(passenger.id) === -1) {
          this.deletedPassengers.push(passenger.id);
        }
      });
    });
  }

  setDtOptions(): void {
    const columns = [];
    this.csvHeaders.forEach(header => {
      columns.push({
        orderable: header === 'Code' || header === 'Name'
      });
    });
    this.dtOptions = {
      columns: columns,
      language: {
        lengthMenu: this.translateService.instant('grid.lengthmenu'),
        zeroRecords: this.translateService.instant('grid.empty'),
        info: this.translateService.instant('grid.info'),
        infoEmpty: this.translateService.instant('grid.infoempty'),
        infoFiltered: this.translateService.instant('grid.infofiltered'),
        search: this.translateService.instant('grid.search'),
        infoThousands: '.',
        loadingRecords: this.translateService.instant('grid.loadingrecords'),
        paginate: {
          first: this.translateService.instant('grid.first'),
          last: this.translateService.instant('grid.last'),
          next: this.translateService.instant('grid.next'),
          previous: this.translateService.instant('grid.previous'),
        },
        aria: {
          sortAscending: this.translateService.instant('grid.sortasc'),
          sortDescending: this.translateService.instant('grid.sortdesc')
        }
      }
    };
  }

  setMappingHeaders(): void {
    this.mappingHeaders = [];
    this.csvHeaders.forEach(header => {
      if (header !== 'Code' && header !== 'Name') {
        this.mappingHeaders.push(header);
      }
    });
    this.allowMappingFields = false;
  }

  setTableHeaders(passengers: any[]): void {
    this.tableHeaders = [];
    passengers.forEach(passenger => {
      Object.keys(passenger.jp).forEach(item => {
        if (this.tableHeaders.indexOf(item) === -1) {
          this.tableHeaders.push(item);
        }
      });
    });
    this.tableHeaders = ['Code', 'Name', 'ART', ...this.tableHeaders.sort()];
  }

  setTagsGroup(type: string): void {
    this.editedPassengers = [];
    this.savedPassengers = [];
    this.selectedResource = type === 'wrapper' ? this.resources[this.selectedWrapper][0]['Name'] : this.selectedResource;
    this.resources[this.selectedWrapper].forEach(resource => {
      if (resource['Name'] === this.selectedResource) {
        this.passengers = resource['Tags'];
        this.tagsGroups = resource['TagsGroups'];
        if (type === 'tagsGroup' && this.selectedTagsGroup !== this.translateService.instant('import.noTagsGroups')) {
          resource['TagsGroups'].forEach(tagsGroup => {
            if (tagsGroup.n === this.selectedTagsGroup) {
              const passengers = [];
              this.passengers.forEach(passenger => {
                if (tagsGroup.tgs.indexOf(passenger.id) !== -1) {
                  passengers.push(passenger);
                }
              });
              this.passengers = passengers;
            }
          });
        }
      }
    });
    this.selectedTagsGroup = type === 'tagsGroup' ? this.selectedTagsGroup : this.translateService.instant('import.noTagsGroups');
    this.setTableHeaders(this.passengers);
    this.setType(this.type);
    if (this.type === 'delete' && this.csvRecords && this.csvRecords.length) {
      this.setDeletingPassengers();
    }
  }

  setType(type: string): void {
    if (type !== this.type) {
      this.csvRecords = [];
      this.duplicates = null;
      this.missingHeaders = null;
      this.filename = undefined;
    }
    this.showPassTable = false;
    this.type = type;
    setTimeout(() => {
      this.showPassTable = true;
    }, 1);
  }

  update(): void {
    this.savedPassengers = [];
    this.passengers.forEach(passenger => {
      this.updateParams.forEach(param => {
        if (param.replaceF) {
          const changeValue = ['Name', 'Code', 'ART'].indexOf(param.replaceF) === -1 ? passenger.jp : passenger;
          const keyValue = ['Name', 'Code', 'ART'].indexOf(param.keyF) === -1 ? passenger.jp : passenger;
          if (param.all || (keyValue[param.keyF] && keyValue[param.keyF].toString() === param.keyV)) {
            if (this.editedPassengers.indexOf(passenger.id) === -1) {
              this.editedPassengers.push(passenger.id);
            }
            if (param.replaceV) {
              changeValue[param.replaceF] = param.replaceV;
            } else if (changeValue[param.replaceF] && ['Name', 'Code', 'ART'].indexOf(param.replaceF) === -1) {
              delete changeValue[param.replaceF];
            }
          }
        }
      });
    });

    let refId = null;
    this.resources[this.selectedWrapper].forEach(resource => {
      if (resource['Name'] === this.selectedResource) {
        refId = parseInt(resource['ReferenceId'], 10);
      }
    });

    const editedPassengers = [];
    this.passengers.forEach(passenger => {
      if (this.editedPassengers.indexOf(passenger.id) !== -1) {
        passenger.n = passenger['Name'];
        passenger.c = passenger['Code'];
        passenger.art = passenger['ART'];
        editedPassengers.push(passenger);
      }
    });

    this.setTableHeaders(this.passengers);
    this.setType(this.type);
    this.importService.addPassengers(editedPassengers, this.selectedWrapper, refId, 0, null, 'update');
  }

  delete(type: string) {
    let refId = null;
    this.resources[this.selectedWrapper].forEach(resource => {
      if (resource['Name'] === this.selectedResource) {
        refId = parseInt(resource['ReferenceId'], 10);
      }
    });

    const deletingPassengers = type === 'all' ? this.passengers : [];
    if (type === 'specific') {
      this.deletedPassengers.forEach(passengerId => {
        const passenger = this.passengers.find(x => { return x.id === passengerId });
        deletingPassengers.push(passenger);
      });

      this.passengers.forEach((passenger, i) => {
        if (this.deletedPassengers.indexOf(passenger.id) === -1) {
          this.deleteParams.forEach(param => {
            if (param.key && ((['Name', 'Code', 'ART'].indexOf(param.key) === -1 && passenger.jp[param.key] === param.value) || passenger[param.key] === param.value)) {
              deletingPassengers.push(passenger);
            }
          });
        }
      });

      deletingPassengers.forEach(passenger => {
        this.passengers.splice(this.passengers.indexOf(passenger), 1);
      });
    }

    this.importService.addPassengers(deletingPassengers, this.selectedWrapper, refId, 0, null, 'delete');

    if (type === 'all') {
      this.passengers = [];
    }
  }

  setPrimaryKey(key: string): void {
    this.updateKey = key;
    this.selectKey = false;
    this.combineHeaderList = [];
    this.csvHeaders.forEach(header => {
      this.combineHeaderList.push({
        csvHeader: header,
        newColumn: false,
        tableHeader: this.tableHeaders && this.tableHeaders.length ? this.tableHeaders[0] : null,
        newColumnName: header
      });
    });
    this.combineHeaders = true;
  }

  combineTables(): void {
    let tableKey = null;
    this.combineHeaderList.forEach(header => {
      tableKey = this.updateKey === header.csvHeader ? header.tableHeader : tableKey;
    });

    this.csvRecords.forEach(record => {
      this.passengers.forEach(passenger => {
        const changePassenger = ['Name', 'Code', 'ART'].indexOf(tableKey) === -1 ? passenger.jp : passenger;
        if (changePassenger[tableKey] === record[this.updateKey]) {
          if (this.editedPassengers.indexOf(passenger.id) === -1) {
            this.editedPassengers.push(passenger.id);
          }
          this.combineHeaderList.forEach(header => {
            if (header.newColumn) {
              passenger.jp[header.newColumnName] = record[header.csvHeader];
            } else if (header.csvHeader !== this.updateKey) {
              const changeValue = ['Code', 'ART', 'Name'].indexOf(header.tableHeader) === -1 ? passenger.jp : passenger;
              changeValue[header.tableHeader] = record[header.csvHeader];
            }
          });
        }
      });
    });

    this.setTableHeaders(this.passengers);
    this.setType(this.type);
    this.combineHeaders = false;
  }

  closeUpdateFile(): void {
    ['selectKey', 'combineHeaders', 'filename'].forEach(item => {
      this[item] = undefined;
    });
  }

  checkDuplicateHeaders(): void {
    this.duplicateHeaders = false;
    this.combineHeaderList.forEach(header => {
      this.duplicateHeaders = header.newColumn && this.tableHeaders.indexOf(header.newColumnName) !== -1 ? true : this.duplicateHeaders;
    });
  }
}
