import { Component, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fh-modal-combine-headers',
  templateUrl: 'modal-combine-headers.template.html',
  providers: []
})

export class ModalCombineHeadersComponent implements OnChanges {
  @Input() headers: string[];
  @Input() csvRecords: any[];
  @Input() show: boolean;
  @Input() updateKey: string;
  @Input() tableHeaders: string[];
  @Input() drivers: any[];
  @Input() editedDrivers: number[];
  @Input() driverKeys: string[];
  @Output() closeModal = new EventEmitter();
  @Output() setDrivers = new EventEmitter();
  combineHeaderList: any[];
  duplicateHeaders: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).indexOf('show') !== -1 && changes.show.currentValue) {
      this.combineHeaderList = [];
      this.headers.forEach(header => {
        this.combineHeaderList.push({
          csvHeader: header,
          newColumn: false,
          tableHeader: this.tableHeaders && this.tableHeaders.length ? this.tableHeaders[0] : null,
          newColumnName: header
        });
      });
    }
  }

  checkDuplicateHeaders(): void {
    this.duplicateHeaders = false;
    this.combineHeaderList.forEach(header => {
      this.duplicateHeaders = header.newColumn && this.tableHeaders.indexOf(header.newColumnName) !== -1 ? true : this.duplicateHeaders;
    });
  }

  combineTables(): void {
    let tableKey = null;
    this.combineHeaderList.forEach(header => {
      tableKey = this.updateKey === header.csvHeader ? header.tableHeader : tableKey;
    });

    this.csvRecords.forEach(record => {
      this.drivers.forEach(driver => {
        const changePassenger = this.driverKeys.indexOf(tableKey) === -1 ? driver.jp : driver;
        if (changePassenger[tableKey] === record[this.updateKey]) {
          if (this.editedDrivers.indexOf(driver.id) === -1) {
            this.editedDrivers.push(driver.id);
          }
          this.combineHeaderList.forEach(header => {
            if (header.csvHeader === 'Exclusive') {
              record[header.csvHeader] = ['Yes', '1', 'true'].includes(record[header.csvHeader]) ? 'Yes' : 'No';
            }
            if (header.newColumn) {
              driver.jp[header.newColumnName] = record[header.csvHeader];
            } else if (header.csvHeader !== this.updateKey) {
              const changeValue = this.driverKeys.indexOf(header.tableHeader) === -1 ? driver.jp : driver;
              changeValue[header.tableHeader] = record[header.csvHeader];
            }
          });
        }
      });
    });

    this.setDrivers.emit();
  }
}
