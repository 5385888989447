import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy, } from '@angular/core';
import { FhChartService } from '../../services/charts/charts.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';


@Component({
  selector: 'fh-dashboard',
  templateUrl: 'dashboard.template.html',
  styleUrls: ['dashboard.template.css'],
  providers: [FhChartService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnDestroy, OnInit {
  timezoneIana: string;
  permissions: {};
  isImpersonated: boolean;


  public constructor(
    private authenticationService: AuthenticationService,
    private authentication: AuthenticationService) {
    this.timezoneIana = this.authenticationService.getTimeZoneIana()
  }

  ngOnInit() {

    this.isImpersonated = this.authentication.getIsImpersonated();
    this.permissions = this.authentication.permissions;
  }

  public ngOnDestroy(): any {
  }
}
