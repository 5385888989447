import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'fh-field-mapper',
  templateUrl: 'field-mapper.template.html',
  providers: []
})

export class FieldMapperComponent implements OnChanges {
  @Input() headers: string[];
  @Output() mappedHeaders = new EventEmitter<any[]>();
  rows: any[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.rows = [];
    changes.headers.currentValue.forEach(header => {
      this.rows.push({
        key: header,
        value: header
      });
    });
  }

  addRow(): void {
    this.rows.push({
      key: '',
      value: ''
    });
  }

  mapHeaders() {
    this.mappedHeaders.emit(this.rows);
  }

  removeRow(i: number) {
    this.rows.splice(i, 1);
  }
}
