import { Component, OnInit, NgZone } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tileLayer, latLng, circle, polygon, Map, marker, icon, TileLayer } from 'leaflet';
import { DeviceService } from '../../services/device/device.service';
import { Device } from 'app/models/device.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MapService } from '../../services/common/map.service';
import * as L from 'leaflet';
import '../../../../node_modules/leaflet-easybutton/src/easy-button.js';

declare var HeatmapOverlay;
declare var PruneCluster;
declare var PruneClusterForLeaflet;

@Component({
    selector: 'fh-devices-map2',
    templateUrl: 'devicesMap2.template.html',
    styleUrls: ['devicesMap2.template.css'],
    providers: [MapService],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                transform: 'translate3d(0, 0, 0)'
            })),
            state('out', style({
                transform: 'translate3d(calc(100% - 20px), 0, 0)'
            })),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out'))
        ]),
    ]
})
export class DevicesMap2ViewComponent implements OnInit {

    leafletView: any;
    selectedDevice: Device;
    deviceId: string;
    loadingSidebar = false;
    googleMaps: TileLayer;
    googleHybrid: TileLayer;
    loading = false;
    markers: any;
    streets: any;
    osm: any;
    cities: any;
    map: Map;
    options;
    devices: Device[];
    menuState = 'out';

    mapData = {
        data: []
    };

    heatmapLayer = new HeatmapOverlay({
        radius: 15,
        maxOpacity: 0.8,
        scaleRadius: false,
        useLocalExtrema: false,
        latField: 'lat',
        lngField: 'lng',
        valueField: 'count'
    });

    constructor(private theMapService: MapService, private zone: NgZone, private http: HttpClient, private deviceService: DeviceService) {
        this.initMap();
    }

    toggleMenu() {
        // 1-line if statement that toggles the value:
        this.menuState = this.menuState === 'out' ? 'in' : 'out';
    }

    selectDevice(deviceId: string): any {
        this.loadingSidebar = true;
        const that = this;

        this.deviceService.getDeviceById(deviceId).subscribe(
            device => {
                this.selectedDevice = device;
                that.loadingSidebar = false;
            }
        );
    }

    ngOnInit() {
    }

    // Leaflet
    initMap() {
        this.osm = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '...' });
        this.googleMaps = tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            detectRetina: true
        });
        this.googleHybrid = tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            detectRetina: true
        });

        this.markers = L.featureGroup();

        this.leafletView = new PruneClusterForLeaflet();

        this.options = {
            layers: [this.osm, this.leafletView],
            zoom: 2,
            center: L.latLng(24.577100, 55.019531)
        };

        const that = this;

        this.loading = true;

        setTimeout(function () {
            that.setPruneCluster(); // Marker clluster version
            that.fillDevices();
        }, 100);
    }

    createIcon() {
        return L.icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        });
    }

    setPruneCluster() {

        const that = this;
        this.theMapService.setPruneCluster(this.leafletView);

        this.leafletView.PrepareLeafletMarker = function (theMarker, data, category) {
            // parse data to icon
            theMarker.setIcon(that.createIcon());

            if (theMarker.getPopup()) {
                theMarker.setPopupContent(data.title + ' - ' + category);
            } else {
                theMarker.bindPopup(data.title + ' - ' + category);
            }

            theMarker.on('click', () => {
                that.zone.run(() => {
                    that.menuState = 'in';
                    that.selectDevice(data.device.id);
                });

            });
        };

        this.leafletView.BuildLeafletClusterIcon = function (cluster) {
            const e = new L.Icon['MarkerCluster']({ className: cluster.hasAlert ? 'prunecluster leaflet-markercluster-icon leaflet-marker-pulse' : 'prunecluster leaflet-markercluster-icon' });
            e.stats = cluster.stats;
            e.population = cluster.population;

            return e;
        };

        const colors = ['#676767', '#008E43', '#971C24', '#EE9234', '#206EB4', '#000000', '#FFE99B'],
            pi2 = Math.PI * 2;

        L.Icon['MarkerCluster'] = L.Icon.extend({
            options: {
                iconSize: new L.Point(44, 44),
                className: 'prunecluster leaflet-markercluster-icon'
            },

            createIcon: function () {
                // based on L.Icon.Canvas from shramov/leaflet-plugins (BSD licence)
                const e = document.createElement('canvas');
                this._setIconStyles(e, 'icon');
                const s = this.options.iconSize;
                e.width = s.x;
                e.height = s.y;
                this.draw(e.getContext('2d'), s.x, s.y);
                return e;
            },

            createShadow: function () {
                return null;
            },

            draw: function (canvas, width, height) {

                const lol = 0;

                let start = 0;
                for (let i = 0, l = colors.length; i < l; ++i) {

                    const size = this.stats[i] / this.population;


                    if (size > 0) {
                        canvas.beginPath();
                        canvas.moveTo(22, 22);
                        canvas.fillStyle = colors[i];
                        let from = start + 0.14;
                        const to = start + size * pi2;

                        if (to < from) {
                            from = start;
                        }
                        canvas.arc(22, 22, 22, from, to);

                        start = start + size * pi2;
                        canvas.lineTo(22, 22);
                        canvas.fill();
                        canvas.closePath();
                    }
                }

                canvas.beginPath();
                canvas.fillStyle = 'white';
                canvas.arc(22, 22, 18, 0, Math.PI * 2);
                canvas.fill();
                canvas.closePath();

                canvas.fillStyle = '#555';
                canvas.textAlign = 'center';
                canvas.textBaseline = 'middle';
                canvas.font = 'bold 12px sans-serif';

                canvas.fillText(this.population, 22, 22, 40);
            }
        });
    }

    onMapReady(map: Map) {
        this.map = map;
        const that = this;

        const baseMaps = {
            'Osm': this.osm,
            'Google maps': this.googleMaps,
            'Google hybrid': this.googleHybrid
        };

        const overlayMaps = {
            'Prunecluster': this.leafletView,
            'Heatmap': this.heatmapLayer,
            // 'Markers': this.markers
        };

        this.map.on('click', () => {
            this.zone.run(() => {
                this.menuState = 'out';
            });
        });

        L.control.layers(baseMaps, overlayMaps, { position: 'topleft' }).addTo(map);

        // Easybuttona

        L.easyButton({
            id: 'fit map button',
            states: [{
                stateName: 'add-markers',
                icon: 'fa-expand-arrows-alt',
                title: 'Fit map',
                onClick: function (control) {
                    that.centerMap();
                }
            }]
        }).addTo(this.map);

        const heatmapToggle = L.easyButton({
            id: 'animated-heatmap-toggle',
            states: [{
                stateName: 'add-markers',
                icon: 'fa-map',
                title: 'Show heatmap',
                onClick: function (control) {
                    that.map.addLayer(that.heatmapLayer);
                    control.state('remove-markers');
                }
            }, {
                stateName: 'remove-markers',
                title: 'Remove heatmap',
                icon: 'fa-undo',
                onClick: function (control) {
                    that.map.removeLayer(that.heatmapLayer);
                    control.state('add-markers');
                }
            }]
        });
        heatmapToggle.addTo(this.map);

        const animatedToggle = L.easyButton({
            id: 'animated-marker-toggle',
            states: [{
                stateName: 'remove-markers',
                title: 'Remove markers',
                icon: 'fa-undo',
                onClick: function (control) {
                    that.map.removeLayer(that.leafletView);
                    control.state('add-markers');
                }
            }, {
                stateName: 'add-markers',
                icon: 'fa-map-marker',
                title: 'Show markers',
                onClick: function (control) {
                    that.map.addLayer(that.leafletView);
                    control.state('remove-markers');
                }
            }]
        });
        animatedToggle.addTo(this.map);

        // End easybutton
    }



    fillDevices() {
        const that = this;
        const markers = [];
        let size = 0;

        this.deviceService.getDevices().subscribe(devices => {
            this.devices = devices;
            this.devices.forEach(device => {
                if (device.lastPosition && Math.abs(device.lastPosition.latitude) > 0.1) {

                    // Prune cluster

                    const theMarker = new PruneCluster.Marker(device.lastPosition.latitude, device.lastPosition.longitude, { title: device.name });

                    theMarker.category = Math.ceil(Math.random() * 6).toString();
                    theMarker.data.popup = device.name;
                    theMarker.data.device = device;

                    markers.push(theMarker);
                    size = size + 1;
                    this.leafletView.RegisterMarker(theMarker);

                    // Heatmap
                    this.mapData.data.push({
                        lat: device.lastPosition.latitude,
                        lng: device.lastPosition.longitude,
                        count: 1
                    });

                    // Normal marker
                    const theNormalMarker = marker([device.lastPosition.latitude, device.lastPosition.longitude], {
                        icon: icon({
                            iconSize: [25, 41],
                            iconAnchor: [13, 41],
                            iconUrl: 'assets/marker-icon.png',
                            shadowUrl: 'assets/marker-shadow.png'
                        })
                    }).bindPopup(device.name).on('click', () => {
                        this.zone.run(() => {
                            this.menuState = 'in';
                            this.selectDevice(device.id);
                        });
                    });

                    this.markers.addLayer(theNormalMarker);
                }
            });

            this.heatmapLayer.setData(this.mapData);
            this.loading = false;

            this.centerMap();

        });
    }

    centerMap() {
        const bounds = this.leafletView.Cluster.ComputeGlobalBounds();
        if (bounds) {
            this.map.fitBounds(new L.LatLngBounds(new L.LatLng(bounds.minLat, bounds.maxLng), new L.LatLng(bounds.maxLat, bounds.minLng)), { padding: [50, 50] });
        }
    }
}
