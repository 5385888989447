import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'fh-passenger-list',
  templateUrl: 'passenger-list.template.html',
  providers: []
})

export class PassengerListComponent implements OnChanges {
  @Input() passengers: string[];
  @Input() type: string;
  @Input() tableHeaders: string[];
  @Input() editedPassengers: number[];
  @Input() deletedPassengers: number[];
  @Input() savedPassengers: number[];
  showTable: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.showTable = false;
    setTimeout(() => {
      this.showTable = true;
    }, 1);
  }
}
