import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'fh-delete-params',
  templateUrl: 'delete-params.template.html',
  providers: []
})

export class DeleteParamsComponent implements OnChanges {
  @Input() params: any[];
  @Input() passengers: boolean;
  @Input() headers: string[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void { }

  addRow(): void {
    this.params.push({
      key: '',
      value: ''
    });
  }

  removeRow(i: number): void {
    this.params.splice(i, 1);
  }
}
