import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { ColorService } from '../common/color.service';
import { TranslateService } from '@ngx-translate/core';
import { tileLayer, latLng, circle, polygon, Map, marker, icon, TileLayer } from 'leaflet';
import { AuthenticationService } from '../authentication/authentication.service';


// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { formatViolationThreshold, humanizeTimespan } from '../common/functions.service';

window['moment'] = Moment;
mTZ()


@Injectable()
export class FhChartService {
    timezoneIana: string;


    constructor(private colors: ColorService, private authenticationService: AuthenticationService) {
        this.timezoneIana = authenticationService.getTimeZoneIana();
        console.log(this.timezoneIana);

        if (this.timezoneIana !== 'undefined') {
            Highcharts.setOptions({
                global: {
                    timezone: this.timezoneIana
                    // timezoneOffset: -4 * 60
                }
            });
        }
    }

    generateDonutChart(theData, format) {
        return new Chart(<any>{
            chart: {
                type: 'pie'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            plotOptions: {
                pie: {
                    size: '100%',
                    shadow: false,
                    center: ['50%', '40%'],
                    allowPointSelect: false,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                        // distance: -50
                    },
                    showInLegend: true
                }
            },
            credits: { enabled: false },
            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '9px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                floating: true,
                enabled: true,
                y: 20,
                backgroundColor: 'rgba(255,255,255,0.5)',
                borderColor: '#ffffff',
            },
            tooltip: {
                pointFormat: '<b>{point.y} ' + format + '</b>'
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        });
    }

    generateStackedChart(theData, theDrilldownData = {}) {

        const chartObject = {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            credits: { enabled: false },
            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '9px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                }
            },
            tooltip: {
                pointFormat: 'Issues: <b>{point.y}</b>'
            },
            series: theData,
            drilldown: theDrilldownData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart = new Chart(<any>chartObject);
        return chart;
    }

    generateStackedChartDashboard(theData, yAxis) {

        const that = this;

        const chartObject = {
            chart: {
                zoomType: 'x',
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            credits: { enabled: false },
            // xAxis: {
            //     type: 'datetime',
            //     dateTimeLabelFormats: { // don't display the dummy year
            //         month: '%e. %b',
            //         year: '%b'
            //     },
            //     title: {
            //         text: ''
            //     },
            // },
            xAxis: {
                type: 'datetime',
                allowDecimals: false,
                minRange: 3600 * 24 * 7 * 1000,
                min: Moment()['tz'](this.authenticationService.getTimeZoneIana()).startOf('day').add(-1, 'day').add(-1, 'month').toDate().getTime(),
                max: Moment()['tz'](this.authenticationService.getTimeZoneIana()).startOf('day').add(-1, 'day').toDate().getTime(),
                startOnTick: false,
                endOnTick: false,
                tickInterval: 24 * 3600 * 1000,
                title: {
                    text: null
                },
                labels: {
                    rotation: -45,
                },
                gridLineWidth: 1
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top'
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y} issues</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart = new Chart(<any>chartObject);
        return chart;
    }

    generateStackedChartDashboardNoIssues(theData, yAxis) {

        const that = this;

        const chartObject = {
            chart: {
                zoomType: 'x',
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            credits: { enabled: false },
            // xAxis: {
            //     type: 'datetime',
            //     dateTimeLabelFormats: { // don't display the dummy year
            //         month: '%e. %b',
            //         year: '%b'
            //     },
            //     title: {
            //         text: ''
            //     },
            // },
            xAxis: {
                type: 'datetime',
                allowDecimals: false,
                minRange: 3600 * 24 * 7 * 1000,
                min: Moment()['tz'](this.authenticationService.getTimeZoneIana()).startOf('day').add(-1, 'day').add(-1, 'month').toDate().getTime(),
                max: Moment()['tz'](this.authenticationService.getTimeZoneIana()).startOf('day').add(-1, 'day').toDate().getTime(),
                startOnTick: false,
                endOnTick: false,
                tickInterval: 24 * 3600 * 1000,
                title: {
                    text: null
                },
                labels: {
                    rotation: -45,
                },
                gridLineWidth: 1
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top'
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>No issues</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart = new Chart(<any>chartObject);
        return chart;
    }

    generateColumnChart(theData, theDrilldownData = {}) {

        const chartObject = {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            credits: { enabled: false },
            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '9px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                pointFormat: 'Issues: <b>{point.y}</b>'
            },
            series: theData,
            drilldown: theDrilldownData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart = new Chart(<any>chartObject);
        return chart;
    }

    generateSplineArea(theData, yAxis) {

        const that = this;

        return new Chart(<any>{
            chart: {
                type: 'areaspline',
                zoomType: 'x',
            },
            title: {
                text: ''
            },
            credits: { enabled: false },
            xAxis: {
                labels: {
                    rotation: -45,
                },
                type: 'datetime',
                title: {
                    text: ''
                }
            },
            yAxis: yAxis,
            tooltip: {
                formatter: function () {
                    return '<b>' + this.y + '</b> issues on <b>' + Moment.utc(this.x)['tz'](that.timezoneIana).format('LL') + '</b>';
                }
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 80,
                verticalAlign: 'top',
                y: 55,
                floating: false,
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    stacking: 'normal',
                    lineColor: '#fff',
                    lineWidth: 1,
                    fillOpacity: 0.5,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#fff'
                    }
                },
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        });
    }

    generateSplineChart(theData): any {
        return new Chart(<any>{
            chart: {
                type: 'spline',
                zoomType: 'x',
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            credits: { enabled: false },
            xAxis: {
                labels: {
                    rotation: -45,
                },
                type: 'datetime',
                title: {
                    text: ''
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                pointFormat: 'Issues: <b>{point.y}</b>'
            },
            series: [{
                name: 'Issues',
                data: theData
            }],
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        });
    }

    generateSpeedChart(theData) {
        return new Chart(<any>{
            chart: {
                type: 'solidgauge'
            },
            title: null,
            pane: {
                center: ['50%', '85%'],
                size: '140%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            tooltip: {
                enabled: false
            },
            // the value axis
            yAxis: {
                min: 0,
                max: 160,
                title: {
                    text: ''
                },
                stops: [
                    [0.1, '#55BF3B'], // green
                    [0.5, '#DDDF0D'], // yellow
                    [0.9, '#DF5353'] // red
                ],
                lineWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                labels: {
                    y: 16
                }
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true,
                        format: '<div style="text-align:center"><span style="font-size:25px;color:black">{y}</span><br/>' +
                            '<span style="font-size:12px;color:silver">km/h</span></div>'
                    },
                    tooltip: {
                        valueSuffix: ' km/h'
                    }
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Speed',
                data: theData,
            }]
        });
    }

    generateRpmChart(theData) {
        return new Chart(<any>{
            chart: {
                type: 'solidgauge'
            },
            title: null,
            pane: {
                center: ['50%', '85%'],
                size: '140%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            tooltip: {
                enabled: false
            },
            // the value axis
            yAxis: {
                min: 0,
                max: 5,
                title: {
                    text: ''
                },
                stops: [
                    [0.1, '#55BF3B'], // green
                    [0.5, '#DDDF0D'], // yellow
                    [0.9, '#DF5353'] // red
                ],
                lineWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                labels: {
                    y: 16
                }
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true,
                        format: '<div style="text-align:center"><span style="font-size:25px;color:black">{y:.1f}</span><br/>' +
                            '<span style="font-size:12px;color:silver">* 1000 / min</span></div>'
                    },
                    tooltip: {
                        valueSuffix: ' revolutions/min'
                    }
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'RPM',
                data: theData
            }]
        });
    }

    generateMultiChart(theData, map = null, plotLines = null, plotBands = null, titlesAndUnitsOfMeasurement, yAxes, translateService: TranslateService = null) {
        let theMarker;
        const theIcon = icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        });

        const that = this;

        return new Chart(<any>{
            chart: {
                zoomType: 'x'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: ''
                },
                plotLines: plotLines,
                plotBands: plotBands,
            },
            yAxis: yAxes,
            credits: {
                enabled: false
            },
            tooltip: {
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#ccc',
                shadow: false,
                shared: true,
                useHTML: true,
                formatter: function () {
                    const date = Moment.utc(this.x)['tz'](that.timezoneIana).format('LLLL Z');
                    const header = '<table class="tip"><caption>' + date + '</caption><tbody>';
                    const footer = '</tbody></table>';
                    let body = '';
                    this.points.forEach(element => {
                        const unitOfMeasurement = titlesAndUnitsOfMeasurement[element.series.name];
                        let value = element.y;
                        if (element.series.name === translateService.instant('chart.ignition') ||
                            element.series.name === translateService.instant('chart.externalPower') ||
                            element.series.name === translateService.instant('chart.GPSFix')) {
                            value = element.y ? translateService.instant('chart.on') : translateService.instant('chart.off');
                        }
                        if (element.series.name === translateService.instant('chart.driverInfo')) {
                            if (element.point.n !== '0') {
                                value = element.point.n;
                            } else {
                                value = translateService.instant('general.none')
                            }
                        }
                        if (element.series.name === translateService.instant('chart.delayInMessage') ||
                            element.series.name === translateService.instant('chart.intervalMessage')) {
                            value = humanizeTimespan(translateService, value);
                        }
                        if (unitOfMeasurement !== '') {
                            body += '<tr><th style="color: ' + element.color + '">' + element.series.name + ' (' + unitOfMeasurement + ') ' + ': </th><td style="text-align: right">' + value + '</td></tr>'
                        } else {
                            body += '<tr><th style="color: ' + element.color + '">' + element.series.name + ': </th><td style="text-align: right">' + value + '</td></tr>'
                        }
                    });

                    return header + body + footer;
                }
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                floating: false,
                x: 0,
                y: 10
            },
            plotOptions: {
                series: {
                    turboThreshold: 99999,
                    point: {
                        events: {
                            mouseOver: function () {
                                if (this.latlon) {
                                    if (theMarker) {
                                        map.removeLayer(theMarker);
                                    }
                                    theMarker = marker(this.latlon, { icon: theIcon }).addTo(map);
                                    map.setView(this.latlon);
                                }
                            },
                            click: function () {
                                const seriesName = this.series.name;
                                return true;
                            },
                            mouseOut: function () {
                                if (theMarker) {
                                    map.removeLayer(theMarker);
                                }
                            }
                        }
                    }
                },
                areaspline: {
                    fillOpacity: 0.1
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        });
    }

    generateFuelChart(theData, plotLines = null, map = null) {
        let theMarker;
        const theIcon = icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        });

        return new Chart(<any>{
            chart: {
                zoomType: 'x'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: ''
                },
                plotLines: plotLines
            },
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value} km'
                },
                title: {
                    text: 'Trip in km'
                }
            }, { // Secondary yAxis
                min: 0,
                max: 101,
                title: {
                    text: 'Fuel tank'
                },
                labels: {
                    format: '{value} %'
                },
                opposite: true
            }],
            tooltip: {
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#ccc',
                shadow: false,
                shared: true,
                useHTML: true,
                valueDecimals: 0,
                headerFormat:
                    '<table class="tip"><caption>{point.key}</caption>'
                    + '<tbody>',
                pointFormat:
                    '<tr><th style="color: {series.color}">{series.name}: </th>'
                    + '<td style="text-align: right">{point.y}</td></tr>',
                footerFormat:
                    '</tbody></table>'
            },
            legend: {
                align: 'center',
                verticalAlign: 'top',
                floating: true,
                x: 0,
                y: 0
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {

                            mouseOver: function () {
                                if (this.latlon) {
                                    if (theMarker) {
                                        map.removeLayer(theMarker);
                                    }
                                    theMarker = marker(this.latlon, { icon: theIcon }).addTo(map);
                                    map.flyTo(this.latlon);
                                }
                            },
                            click: function () {
                                const seriesName = this.series.name;
                                return true;
                            },
                            mouseOut: function () {
                                if (theMarker) {
                                    map.removeLayer(theMarker);
                                }
                            }
                        }
                    }
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        });
    }

    generateColumnRangeChart(theData, xAxis, translateService: TranslateService = null) {

        const that = this;
        return new Chart(<any>{
            chart: {
                zoomType: 'y',
                type: 'columnrange',
                inverted: true,
                spacingLeft: 0
            },
            credits: {
                enabled: false
            },
            title: {
                text: null,
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            const point = event.point as any;
                            location.href = '/#/IssueDetails/Index/' + point.issueId;
                        }
                    }
                }
            },
            xAxis: {
                categories: xAxis,
                showEmpty: false,
                title: {
                    text: null
                },
                gridLineWidth: 1
            },
            tooltip: {
                formatter: function () {
                    const threshold = formatViolationThreshold(translateService, this.point.issueType, this.point.violationLowerThreshold);

                    let tooltip = '<b>' + Moment.utc(this.point.date)['tz'](that.timezoneIana).format('LL') + '</b> ' +
                    (this.point.violationDuration ? translateService.instant('general.duration') + ' <b>' + Moment.duration(this.point.violationDuration * 1000, 'milliseconds').humanize() + '</b>' : '') +
                    (this.point.violationLowerThreshold ? translateService.instant('general.violationThresholds') + ' <b>' + threshold + '</b>' : '')
                    if (this.point.sensorName) {
                        tooltip += ', ' + translateService.instant('chart.sensorDefaultName') + ' <b>' + this.point.sensorName + '</b>';
                    }
                    return tooltip;
                }
            },
            yAxis: {
                type: 'datetime',
                allowDecimals: false,
                // endOnTick: true,
                // startOnTick: true,
                // tickInterval: 1000 * 60 * 60 * 24, // every day
                minRange: 3600 * 24 * 7 * 1000,
                max: Moment().toDate().getTime(),
                title: {
                    text: null
                },
                labels: {
                    rotation: -45,
                },
                gridLineWidth: 1
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        });
    }
}
