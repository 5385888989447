import { Component, OnInit } from '@angular/core';
import { ImportService } from 'app/services/import/import.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fh-driver-import',
  templateUrl: 'driver-import.template.html',
  providers: []
})

export class DriverImportComponent implements OnInit {
  alert: any;
  platforms: any;
  wrapper: string;
  driversGroup: any;
  mappedHeaders: any[] = [];
  type = 'add';
  drivers: any[] = [];
  usingDrivers: any[] = [];

  deleteParams: any[] = [{
    key: '',
    value: ''
  }];
  updateParams: any[] = [{
    all: false,
    keyF: '',
    replaceF: '',
    replaceV: ''
  }];
  tableHeaders: string[];

  primaryUpdateKey: string;
  openSelectKeyModal: boolean;
  openCombineHeadersModal: boolean;

  editedDrivers: number[] = [];
  deletedDrivers: number[] = [];
  savedDrivers: number[] = [];

  allowMappingFields: boolean;
  csvHeaders: string[];
  csvRecords: any[];
  dtOptions: any;
  duplicates: any;
  filename: string;
  mappingHeaders: string[];
  missingHeaders: string[];
  selectedRecords: any[];
  uniqueValues: any;
  driverKeys: string[] = ['Code', 'Name', 'Description', 'Phone number', 'Mobile key', 'Exclusive'];
  exclusive: boolean;
  platformsFound: boolean;

  constructor(
    private importService: ImportService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.importService.getDriversDone().subscribe(
      result => {
        console.log(result);
        this.platformsFound = Object.keys(result).length > 0;
        if (!this.platformsFound) {
          this.alert = {
            text: this.translateService.instant('alert.noPlatformsFound'),
            type: 'alert-danger'
          };
        }
        this.platforms = result;
      }
    );

    this.importService.getDrivers();

    this.importService.getCrudDriversDone().subscribe(
      result => {
        if (result.status === 'success') {
          this.savedDrivers = JSON.parse(JSON.stringify(this.editedDrivers));
          this.editedDrivers = [];
          this.alert = {
            text: this.translateService.instant('alert.' + result.type + 'DriversSuccess'),
            type: 'alert-success'
          };
        } else {
          this.alert = {
            text: this.translateService.instant('alert.addingDriversFail'),
            type: 'alert-danger'
          };
        }
        setTimeout(() => {
          this.alert = undefined;
        }, 5000);
      }
    );
  }

  closeModal(): void {
    ['openSelectKeyModal', 'openCombineHeadersModal', 'filename'].forEach(item => {
      this[item] = undefined;
    });
  }

  getFile(file: any): void {
    Object.keys(file).forEach(key => {
      this[key] = file[key];
    });
    this.openSelectKeyModal = this.type === 'update';
    if (this.type === 'delete') {
      this.setDeletingDrivers();
    }
  }

  setDeletingDrivers(): void {
    this.deletedDrivers = [];
    this.usingDrivers.forEach(driver => {
      this.csvRecords.forEach(record => {
        let delDriver = true;
        Object.keys(record).forEach(item => {
          const it = this.driverKeys.indexOf(item) !== -1 ? driver[item] : driver.jp[item];
          delDriver = it && record[item] && record[item] === it ? delDriver : false;
        });
        if (delDriver && this.deletedDrivers.indexOf(driver.id) === -1) {
          this.deletedDrivers.push(driver.id);
        }
      });
    });
  }

  setDrivers(drivers: any[], type: string): void {
    ['openSelectKeyModal', 'openCombineHeadersModal'].forEach(item => {
      this[item] = undefined;
    });
    this.tableHeaders = [];
    const params = { n: 'Name', c: 'Code', ds: 'Description', p: 'Phone number', pwd: 'Mobile key' };
    if (type === 'updateTable' || type === 'tableCombine') {
      this.usingDrivers.forEach(usingDriver => {
        this.drivers.forEach(driver => {
          if (usingDriver.id === driver.id) {
            this.drivers.splice(this.drivers.indexOf(driver), 1, usingDriver);
          }
        });
      });
    } else {
      this.drivers = drivers;
    }
    this.drivers.forEach(driver => {
      Object.keys(driver.jp).forEach(item => {
        if (this.tableHeaders.indexOf(item) === -1) {
          this.tableHeaders.push(item);
        }
      });
      if (type === 'newDrivers') {
        Object.keys(params).forEach(key => {
          driver[params[key]] = driver[key] ? driver[key] : '';
        });
        driver['Exclusive'] = driver.f >= 4 ? 'Yes' : 'No';
      }
    });
    this.tableHeaders = [...this.driverKeys, ...this.tableHeaders.sort()];
    this.setDriversGroup(this.driversGroup);
  }

  setDriversGroup(driversGroup: any): void {
    this.driversGroup = driversGroup;
    if (driversGroup.name !== this.translateService.instant('importDriver.noDriversGroup')) {
      this.usingDrivers = [];
      this.drivers.forEach(driver => {
        if (driversGroup.drivers.indexOf(driver.id) !== -1) {
          this.usingDrivers.push(JSON.parse(JSON.stringify(driver)));
        }
      });
    } else {
      this.usingDrivers = JSON.parse(JSON.stringify(this.drivers));
    }
    if (this.type === 'delete' && this.csvRecords && this.csvRecords.length) {
      this.setDeletingDrivers()
    }
  }

  setExclusive(exclusive: boolean): void {
    this.exclusive = exclusive;
    console.log(exclusive);
  }

  setMappingHeaders(): void {
    this.mappingHeaders = [];
    this.csvHeaders.forEach(header => {
      if (header !== 'Name') {
        this.mappingHeaders.push(header);
      }
    });
    this.allowMappingFields = false;
  }

  setType(type: string): void {
    if (type !== this.type) {
      this.csvRecords = [];
      this.selectedRecords = [];
      this.duplicates = null;
      this.missingHeaders = null;
      this.filename = undefined;
    }
    this.type = type;
  }
}
