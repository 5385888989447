import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'fh-select-column-modal',
  templateUrl: 'select-column-modal.template.html'
})
export class SelectColumnModalComponent implements OnChanges {
  @Input() newHeader: string;
  @Input() newFile: any;
  @Input() leadingHeaders: string[];
  @Output() selectColumns = new EventEmitter<any>();
  allowCombine: boolean;
  enoughHeadersSelected: boolean;
  duplicateHeaders: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.newFile['leadingHeaders'] = [];
    this.newFile['selectedHeaders'] = [];
    this.newFile['duplicateHeaders'] = [];
    changes.newFile.currentValue.headers.forEach(header => {
      this.newFile.leadingHeaders.push(header);
      this.newFile.selectedHeaders.push(header === this.newHeader);
      this.newFile.duplicateHeaders.push(false);
    });
  }

  checkDuplicateHeaders(): void {
    this.newFile.duplicateHeaders = [];
    this.newFile.leadingHeaders.forEach((header: string, i: number) => {
      let found = false;
      this.newFile.leadingHeaders.forEach((headerCheck: string, index: number) => {
        found = i !== index && header === headerCheck && this.newFile.selectedHeaders[index] && this.newFile.selectedHeaders[i] ? true : found;
      });
      found = header !== this.newHeader && this.leadingHeaders.indexOf(header) !== -1 && this.newFile.selectedHeaders[i] ? true : found;
      this.newFile.duplicateHeaders.push(found);
    });
    this.duplicateHeaders = this.newFile.duplicateHeaders.indexOf(true) !== -1;
  }

  checkHeaderAmount() {
    let amount = 0;
    this.newFile.selectedHeaders.forEach(header => {
      if (header) {
        amount += 1;
      }
    });
    this.enoughHeadersSelected = (amount > 0 && !this.newHeader) || (amount > 1 && this.newHeader) ? true : false;
  }

  select() {
    const headers = [];
    this.newFile.selectedHeaders.forEach((selected: boolean, i: number) => {
      if (selected) {
        headers.push(
          {
            name: this.newFile.leadingHeaders[i],
            key: this.newFile.headers[i]
          }
        );
      }
    });
    this.selectColumns.emit(headers);
  }
}
