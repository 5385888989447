import { Component, AfterViewInit, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import 'jquery-slimscroll';
import { AuthenticationService } from '../../../services/authentication/authentication.service';

declare var jQuery: any;

@Component({
  selector: 'fh-navigation',
  templateUrl: 'navigation.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {

  hasFuel: any;
  // Permissions
  hasReporting: any;
  hasCalculationSettings: any;
  hasLocations: any;
  hasTrips: any;
  hasAdvice: any;
  hasSchedule: any;
  hasSettings: any;
  hasAssets: any;
  hasDevices: any;
  hasCustomers: any;

  showId: any;
  id: number;
  private sub: any;
  permissions: {};
  isImpersonated: boolean;

  constructor(private cd: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, private authentication: AuthenticationService) {
    this.id = 0;
    this.showId = '';
  }

  ngAfterViewInit() {
    jQuery('#side-menu').metisMenu();

    if (jQuery('body').hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%'
      })
    }
  }

  ngOnInit() {

    this.isImpersonated = this.authentication.getIsImpersonated();

    this.permissions = this.authentication.permissions;

    this.router.events.subscribe(params => {
      // console.log(params);
      this.cd.markForCheck();
    });

    this.sub = this.route.children[0].params.subscribe(params => {
      console.log('Update route ' + params['id']);
      this.id = params['id'];
      this.showId = this.id ? this.id.toString().substring(0, 4) : '';
      this.cd.markForCheck();
    });
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) === 1;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
