import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';
import { throwError } from 'rxjs/internal/observable/throwError';


import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';

export class Trip {
    beginLongitude: any;
    endLatitude: any;
    endLongitude: any;
    beginLatitude: any;
    locations: any;
    segmentDistanceInKilometers: any;
    boundingDistanceInMetres: any;
    endCity: any;
    endLabel: any;
    endDateTime: any;
    beginCity: any;
    beginLabel: any;
    beginDateTime: any;
    driverName: any;
    driverId: any;
    deviceId: any;
    assetId: any;
    id: any;
}

@Injectable()
export class TripService {
    token: string;
    timestamp;
    url = '';

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('device');
        this.token = authenticationService.getAuthToken();
    }

    getTripsForDevice(deviceId: string): Observable<Trip[]> {

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting schedule from service');
        return this.http.get('./assets/data/trips.json')
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    parseResponse(json: any): Trip[] {

        console.log(json);
        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' schedules.');

        const ident = 1;
        const trips: Trip[] = [];

        json.Trips.forEach(item => {
            const trip = new Trip();
            trip.id = item.Id;
            trip.assetId = item.AssetId;
            trip.deviceId = item.DeviceId;
            trip.driverId = item.DriverId;
            trip.driverName = item.DriverName;

            trip.beginDateTime = item.BeginDateTime;
            trip.beginLabel = item.BeginLabel;
            trip.beginCity = item.BeginCity;
            trip.beginLatitude = item.BeginLatitude;
            trip.beginLongitude = item.BeginLongitud;

            trip.endDateTime = item.EndDateTime;
            trip.endLabel = item.EndLabel;
            trip.endCity = item.EndCity;
            trip.endLatitude = item.EndLatitude;
            trip.endLongitude = item.EndLongitud;

            trip.boundingDistanceInMetres = item.BoundingDistanceInMetres;
            trip.segmentDistanceInKilometers = item.SegmentDistanceInKilometers;

            trip.locations = item.Locations;

            trips.push(trip);
        });

        this.timestamp = new Date().getTime();
        return trips;
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
