import { Customer } from 'app/models/customer.model';
import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';
import { DeviceService } from '../../services/device/device.service';

import { Issue } from 'app/models/issue.model';
import * as moment from 'moment';
import { Subject } from 'rxjs/Subject';

import { Chart } from 'angular-highcharts';

import { DeviceType } from 'app/models/devicetype.model';
import { IssueType } from 'app/models/issuetype.model';
import { DataTableDirective } from 'angular-datatables';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from '../../services/common/translator.service';
import { ActivatedRoute } from '@angular/router';

import { BsDatepickerConfig, BsLocaleService, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { Device } from 'app/models/device.model';
window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-devices',
  templateUrl: 'devices.template.html',
  styleUrls: ['devices.template.css']
})
export class DevicesViewComponent implements OnInit, OnDestroy {
  token: string;
  countrySelect = false;

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dropdown_IssueType = '';
  dropdown_DeviceType = '';

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  today = new Date();
  daterangepickerModel: Date[];
  maxDate = new Date();

  dtOptions = {};
  loading = false;

  min: number;
  max: number;

  showOnlyFlagged = false;
  showOnlyActive = true;

  issueTypes: IssueType[];
  deviceTypes: DeviceType[];

  customers: Customer[];

  dropdown_CustomerName = '';
  languageLoaded: boolean;
  timezoneIana: string;

  constructor(private localeService: BsLocaleService, private route: ActivatedRoute, private translatorService: TranslatorService, private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private deviceService: DeviceService) {
    const that = this;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana()


    this.maxDate.setDate(this.today.getDate() - 7);
    this.daterangepickerModel = [this.maxDate, this.today];

    this.dpConfig.containerClass = 'theme-default'; // or whatever color
    this.dpConfig.dateInputFormat = 'll';
    // range picker
    this.dpConfig.rangeSeparator = ' - ';
    this.dpConfig.rangeInputFormat = 'll';

    // Set issuetype from get
    this.route.queryParams.subscribe(params => {
      const issueType = params['IssueType'];
      if (issueType) {
        that.dropdown_IssueType = issueType;
      }
    });

  }

  seachChanged(value) {
    console.log('Search changed');
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(value).draw();
    });
  }

  checkFilters() {
    this.fireFilter(1);
  }

  fireFilter(event): void {

    if (event != null) {
      console.log('Fire update');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

        // Search for the devicetype
        if (this.dropdown_DeviceType !== '') {
          dtInstance.column('deviceTypeId:name').search(this.dropdown_DeviceType);
        } else {
          dtInstance.column('deviceTypeId:name').search('');
        }

        if (this.dropdown_CustomerName !== '') {
          dtInstance.column('customerName:name').search(this.dropdown_CustomerName);
        } else {
          dtInstance.column('customerName:name').search('');
        }

        if (this.showOnlyFlagged === true) {
          dtInstance.column('isFlagged:name').search('true');
        } else {
          dtInstance.column('isFlagged:name').search('');
        }

        if (this.showOnlyActive === true) {
          dtInstance.column('isActive:name').search('true');
        } else {
          dtInstance.column('isActive:name').search('');
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();

    // Had to reset the array...
    $.fn['dataTable'].ext.search = [];
  }


  ngOnInit(): void {
    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true; this.initGrid();
    });
  }

  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    const commonExportOptions = {
      modifier: {
        page: 'all',
        search: 'none'
      },
      columns: ':visible[tabindex]'
    };
    const fileName = 'device_overview';
    const header = this.translateService.instant('menu.deviceoverview');

    this.dtOptions = {
      buttons: [{
        extend: 'copy',
        text: 'Copy',
        exportOptions: commonExportOptions
      }, {
        extend: 'print',
        text: 'Print',
        exportOptions: commonExportOptions,
        messageTop: header
      }, {
        extend: 'csv',
        text: 'CSV',
        exportOptions: commonExportOptions,
        filename: fileName
      }, {
        extend: 'excel',
        text: 'XLS',
        exportOptions: commonExportOptions,
        filename: fileName
      }, {
        extend: 'pdf',
        text: 'PDF',
        exportOptions: commonExportOptions,
        orientation: 'landscape',
        filename: fileName,
        messageTop: header
      }],
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      responsive: true,
      deferLoading: 0,
      order: [[1, 'asc']],
      ajax: {
        url: that.deviceService.getPagingUrl(),
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        console.log('init complete');
        that.checkFilters();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'deviceTypeId']
      },
      columns: [
        {
          name: 'id',
          data: 'id',
          orderable: false,
          title: '',
          width: '20',
          render: function (data, type, row) {
            return '<a class=\'btn btn-primary btn-outline btn-grid\' href=\'/#/DeviceDetails/Index/' + data + '\'><i class=\'fas fa-fw fa-info\'></i> <span class="hidden-sm hidden-xs">Details</span></a>';
          }
        },
        {
          name: 'name',
          data: 'name',
          title: this.translateService.instant('general.name')
        },
        {
          name: 'unitId',
          data: 'unitId',
          title: this.translateService.instant('general.unitId')
        },
        {
          name: 'lastCommunication',
          data: 'lastCommunication',
          title: this.translateService.instant('general.lastCommunication'),
          render: function (data, type, row) {
            const date = Moment.utc(data)['tz'](that.timezoneIana);
            return date.format('lll')
          }
        },
        {
          name: 'note',
          data: 'note',
          title: this.translateService.instant('general.note'),
          visible: false
        },
        {
          name: 'deviceTypeId',
          data: 'deviceTypeId',
          title: this.translateService.instant('general.devicetype'),
          visible: false
        },
        {
          name: 'deviceTypeName',
          data: 'deviceTypeName',
          title: this.translateService.instant('general.devicetype')
        },
        {
          name: 'manufactorerName',
          data: 'manufactorerName',
          title: this.translateService.instant('general.manufacturer')
        },
        {
          name: 'customerName',
          data: 'customerName',
          title: this.translateService.instant('general.customer')
        },
        {
          name: 'isFlagged',
          data: 'isFlagged',
          title: this.translateService.instant('general.isFlagged'),
          visible: true,
          width: '40',
          render: function (data, type, row) {
            return data === true ? '<i class="fa-fw fa-2x fa-star fas" style=" font-size: 1.3em;"></i>' : '<i class="fa-fw fa-2x fa-star far" style=" font-size: 1.3em;"></i>';
          }
        },
        {
          name: 'isActive',
          data: 'isActive',
          title: this.translateService.instant('general.isActive'),
          visible: true,
          width: '40',
          render: function (data, type, row) {
            return data === true ? '<i class="fa-fw fa-2x fa-check-square far" style=" font-size: 1.3em;"></i>' : '<i class="fa-fw fa-2x fa-square far" style=" font-size: 1.3em;"></i>';
          }
        },
        {
          name: 'firmware',
          data: 'firmware',
          title: this.translateService.instant('general.firmware')
        }],
      pageLength: 10,
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, this.translateService.instant('general.all')]],
      language: {
        'lengthMenu': this.translateService.instant('grid.lengthmenu'),
        'zeroRecords': this.translateService.instant('grid.empty'),
        'info': this.translateService.instant('grid.info'),
        'infoEmpty': this.translateService.instant('grid.infoempty'),
        'infoFiltered': this.translateService.instant('grid.infofiltered'),
        'search': this.translateService.instant('grid.search'),
        'infoThousands': '.',
        'processing': '<div class="sk-spinner sk-spinner-wave"><div class="sk-rect1"></div><div class="sk-rect2"></div><div class="sk-rect3"></div><div class="sk-rect4"></div><div class="sk-rect5"></div></div>',
        'loadingRecords': this.translateService.instant('grid.loadingrecords'),
        'paginate': {
          'first': this.translateService.instant('grid.first'),
          'last': this.translateService.instant('grid.last'),
          'next': this.translateService.instant('grid.next'),
          'previous': this.translateService.instant('grid.previous'),
        },
        'aria': {
          'sortAscending': this.translateService.instant('grid.sortasc'),
          'sortDescending': this.translateService.instant('grid.sortdesc'),
        }
      },
    };
  }
}
