
export class InventoryAccount {
    name: any;
    id: any;
    createdDate: any;
    dateCreated: any;
    identifier: any;
    limitHistoryInDays: any;
    date: any;
    issueCount: any;
    jobCount: any;
    userCount: any;
    platformCount: any;
    deviceCount: any;
    status: any;
    isActive: any;
}



export class AccountPlatform {
    referenceId: any;
    name: any;
    id: any;
    createdDate: any;
    dateCreated: any;
    identifier: any;
    timezoneIana: any;
}
