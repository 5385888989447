import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'fh-driver-field-mapper',
  templateUrl: 'driver-field-mapper.template.html',
  providers: []
})

export class DriverFieldMapperComponent implements OnChanges {
  @Input() headers: string[];
  @Input() rows: any[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.rows.splice(0, this.rows.length);
    changes.headers.currentValue.forEach(header => {
      this.rows.push({
        key: header,
        value: header
      });
    });
  }

  addRow(): void {
    this.rows.push({
      key: '',
      value: ''
    });
  }

  removeRow(i: number) {
    this.rows.splice(i, 1);
  }
}
