import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { InventoryAccount } from '../../models/account.model';
import { AccountType } from '../../common/enums';
import { throwError } from 'rxjs';

@Injectable()
export class AccountService {

    token: any;
    url = '';
    timestamp: number;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('Account');
        this.token = authenticationService.getAuthToken();
    }

    getAccounts(): Observable<InventoryAccount[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting accounts from service');
        return this.http.get(this.url, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAccountsWithUse(): Observable<InventoryAccount[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting accounts from service');
        return this.http.get(this.url + 'GetAccountsWithUse', { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAccountsWithUseHistory(): Observable<InventoryAccount[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting accounts from service');
        return this.http.get(this.url + 'getAccountsWithUseHistory', { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAccountTypes(): Observable<AccountType[]> {
        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });
        const urlExtra = 'AllAccountTypes';
        console.log('getting account types from service');
        return this.http.get(this.url + urlExtra, { headers: headers })
            .map((data) => {
                return (data as AccountType[]);
            })
            .catch(this.handleError);
    }

    getAccountById(id: any): Observable<InventoryAccount> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting accounts from service');
        return this.http.get(this.url + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponseData(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    parseResponse(json: any): InventoryAccount[] {
        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Accounts.');

        const ident = 1;
        const accounts: InventoryAccount[] = [];

        json.forEach(item => {
            let account = new InventoryAccount();
            account = this.parseResponseData(item);
            accounts.push(account);
        });

        this.timestamp = new Date().getTime();
        return accounts;
    }

    parseResponseData(item: any): InventoryAccount {
        const account = new InventoryAccount();
        account.id = item.id;
        account.name = item.name;
        account.status = item.status;
        account.isActive = item.isActive;

        account.date = item.date;
        account.issueCount = item.issueCount;
        account.jobCount = item.jobCount;

        account.userCount = item.userCount;
        account.platformCount = item.platformCount;
        account.deviceCount = item.deviceCount;

        account.createdDate = item.createdDate;
        account.identifier = item.identifier;
        account.limitHistoryInDays = item.limitHistoryInDays;
        return account
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
