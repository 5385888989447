import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { AccountService } from '../../services/accounts/accounts.service';
import { UserService } from '../../services/users/user.service';
import { InventoryAccount } from '../../models/account.model';
import { AppUser, UpdatePasswordRequest } from '../../models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { languages, whitelabels } from '../../common/enums';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { BsLocaleService } from 'ngx-bootstrap';
window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-profile',
  templateUrl: 'profile.template.html'
})
export class ProfileComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  account: InventoryAccount;
  loading: boolean;
  user: AppUser;
  users: AppUser[];
  languages: { name: string; path: any; }[];
  currentLang;
  updateMessage: string;
  isSaved: boolean;

  oldPassword: string;
  confirmPassword: string;
  newPassword: string;
  whitelabels: { value: string; name: any; }[];

  constructor(private localeService: BsLocaleService, private translate: TranslateService, private authenticationService: AuthenticationService, private accountService: AccountService, private userService: UserService) {

    this.user = new AppUser();
    this.user.timezoneIana = 'Europe/Amsterdam'

    const userId = authenticationService.getUserId();
    this.currentLang = authenticationService.getLanguage();

    this.loading = true;

    this.userService.getUserById(userId).subscribe(user => {
      this.user = user;
    });

    this.accountService.getAccountById('00000000-0000-0000-0000-000000000000').subscribe(account => {
      this.account = account;
      this.loading = false;

      this.userService.getUsersByAccountId(this.account.id).subscribe(result => {
        this.users = result;
      });
    });
  }

  ngOnInit(): void {

    this.languages = Object.keys(languages)
      .filter(k => typeof languages[k] === 'string')
      .map(n => ({ name: n, path: languages[n] }));

    this.whitelabels = Object.keys(whitelabels)
      .filter(k => typeof whitelabels[k] === 'string')
      .map(n => ({ value: n, name: whitelabels[n] }));

  }

  save() {
    this.loading = true;
    this.userService.updateUserProfile(this.user).subscribe(result => {
      this.loading = false;

      this.updateMessage = this.translate.instant('general.profileUpdated');
      this.isSaved = true;
    });
  }

  savePassword() {
    if (this.newPassword === this.confirmPassword) {
      const request = new UpdatePasswordRequest();
      request.id = this.user.id;
      request.userName = this.user.name;
      request.oldPassword = this.oldPassword;
      request.newPassword = this.newPassword;
      this.userService.updateUserPassword(request).subscribe(result => {
        this.updateMessage = this.translate.instant('general.passwordUpdated');
        this.isSaved = true;
      });
    }
  }

  onChange($event, lang) {
    this.isSaved = false;

    this.user.language = lang;
    if (lang !== this.translate.currentLang) {
      this.translate.use(lang);
      Moment.locale(lang);
      this.localeService.use(lang);
    }

    // Set language to localStorage
    this.authenticationService.setLanguage(lang);
  }
}
