import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ImportService } from 'app/services/import/import.service';

@Component({
  selector: 'fh-driver-import-functionality',
  templateUrl: 'driver-import-functionality.template.html',
  providers: []
})

export class DriverImportFunctionalityComponent {
  @Input() deleteParams: any[];
  @Input() driverKeys: any[];
  @Input() drivers: any[];
  @Input() driversGroup: any;
  @Input() editedDrivers: number[];
  @Input() deletedDrivers: number[];
  @Input() mappedHeaders: any[];
  @Input() records: any[];
  @Input() refId: number;
  @Input() savedDrivers: any[];
  @Input() type: string;
  @Input() updateParams: any[];
  @Input() wrapper: string;
  @Input() exclusive: boolean;

  @Output() setDrivers = new EventEmitter();

  constructor(private importService: ImportService) { }

  add(): void {
    const drivers = [];
    this.records.forEach(record => {
      const driver = {
        n: record['Name'],
        jp: {},
        c: '',
        ds: '',
        p: '',
        pwd: '',
        f: this.exclusive ? 5 : 1
      }

      this.mappedHeaders.forEach(header => {
        if (header.key.trim() && header.value.trim() && header.key !== 'Name') {
          if (header.key === 'Exclusive') {
            driver.f = ['Yes', '1', 'true'].includes(record[header.value]) ? 5 : 1;
          } else if (this.driverKeys.indexOf(header.key) !== -1 ) {
            const list = { 'Code': 'c', 'Description': 'ds', 'Phone number': 'p', 'Mobile key': 'pwd' };
            driver[list[header.key]] = record[header.value] ? record[header.value] : '';
          } else {
            driver['jp'][header.key] = record[header.value];
          }
        }
      });

      drivers.push(driver);
    });

    this.importService.crudDrivers(drivers, this.wrapper, this.refId, this.driversGroup, 'create');
  }

  update(): void {
    this.savedDrivers.splice(0, this.savedDrivers.length);
    this.drivers.forEach(driver => {
      this.updateParams.forEach(param => {
        if (param.replaceF) {
          const changeValue = this.driverKeys.indexOf(param.replaceF) === -1 ? driver.jp : driver;
          const keyValue = this.driverKeys.indexOf(param.keyF) === -1 ? driver.jp : driver;
          if (param.all || (keyValue[param.keyF] && keyValue[param.keyF].toString() === param.keyV)) {
            if (param.replaceV) {
              if (this.editedDrivers.indexOf(driver.id) === -1) {
                this.editedDrivers.push(driver.id);
              }
              changeValue[param.replaceF] = param.replaceV;
            } else if (changeValue[param.replaceF] && param.replaceF !== 'Code') {
              if (this.editedDrivers.indexOf(driver.id) === -1) {
                this.editedDrivers.push(driver.id);
              }
              delete changeValue[param.replaceF];
            }
          }
        }
      });
    });

    const editedDrivers = [];
    this.drivers.forEach(driver => {
      if (this.editedDrivers.indexOf(driver.id) !== -1) {
        driver.n = driver['Name'];
        driver.c = driver['Code'];
        driver.ds = driver['Description'];
        driver.p = driver['Phone number'];
        driver.pwd = driver['Mobile key'];
        driver.f = driver['Exclusive'] === 'Yes' ? 5 : 1;
        editedDrivers.push(driver);
        console.log(driver);
      }
    });

    this.setDrivers.emit();

    this.importService.crudDrivers(editedDrivers, this.wrapper, this.refId, null, 'update');
  }

  delete(type: string): void {
    const deletingDrivers = [];
    if (type === 'specific') {
      this.deletedDrivers.forEach(driverId => {
        deletingDrivers.push({ id: driverId });
        const driver = this.drivers.find(x => { return x.id === driverId });
        this.drivers.splice(this.drivers.indexOf(driver), 1);
      });
      this.drivers.forEach((driver, i) => {
        if (this.deletedDrivers.indexOf(driver.id) === -1) {
          this.deleteParams.forEach(param => {
            if (param.key && ((this.driverKeys.indexOf(param.key) === -1 && driver.jp[param.key] === param.value) || driver[param.key] === param.value)) {
              deletingDrivers.push({ id: driver.id });
              this.drivers.splice(this.drivers.indexOf(driver), 1);
            }
          });
        }
      });
    } else {
      this.drivers.forEach(driver => {
        deletingDrivers.push({ id: driver.id });
      });
      this.drivers.splice(0, this.drivers.length);
    }

    this.importService.crudDrivers(deletingDrivers, this.wrapper, this.refId, null, 'delete');
  }
}
