import { Component, OnInit, AfterViewInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { tileLayer, latLng, circle, polygon, Map, marker, icon } from 'leaflet';
import { Subscription } from 'rxjs/Subscription';
import { Issue } from 'app/models/issue.model';
import { DeviceService } from 'app/services/device/device.service';
import { Chart } from 'angular-highcharts';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { Observable } from 'rxjs/Observable';

import { ToastrService } from 'ngx-toastr';

import { BsDatepickerConfig } from 'ngx-bootstrap';
import { Schedule } from 'app/models/schedule.model';
import { FhChartService } from '../../services/charts/charts.service';
import { ColorService } from '../../services/common/color.service';
import { IssueType } from '../../models/issuetype.model';
import { forkJoin } from 'rxjs';

// Timezone
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';

declare var L;

@Component({
    selector: 'fh-fh-device-details',
    templateUrl: 'deviceDetails.template.html',
    providers: [FhChartService],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceDetailsViewComponent implements OnInit {

    chartColumnRangeEnabled: boolean;
    theIssueTypesObservable: Observable<IssueType[]>;
    issuesByDevice: Observable<Issue[]>;
    loadingIssues: boolean;
    chartColumnRange: Chart;
    yesterday: number;
    dayAfterYesterday: number;
    oneWeek: number;
    twoWeeks: number;
    oneMonth: number;
    twoMonths: number;
    endYesterday: number;
    startYesterday: number;
    issuesLastMonthCount = 0;
    issuesLastMonthCountOld = 0;
    issuesLastMonthCountPercentage = 0;
    issuesLastWeekCount = 0;
    issuesLastWeekCountOld = 0;
    issuesLastWeekCountPercentage = 0;
    locationsLastMonthCount = 0;
    locationsLastMonthCountOld = 0;
    locationsLastMonthCountPercentage = 0;
    locationsLastWeekCount = 0;
    locationsLastWeekCountOld = 0;
    locationsLastWeekCountPercentage = 0;

    osm: any;
    chartLocationCount: Chart;
    chartDistanceCount: Chart;
    chartIssueCount: Chart;

    progress = [];
    hasAdvice: any;
    hasSchedule: any;
    hasNotes: any;
    renderDateTime: number;
    insertAdviseSchedule: boolean;
    deviceType: any;

    note: string;
    isFlagged: boolean;

    locationsThisMonth: number;
    locationsLastWeek: number;
    issuesThisMonth: number;
    issuesThisWeek: number;

    sub: Subscription;
    loading = false;
    loadingNote = false;
    loadingFlagged = false;
    loadingIssueCount = false;
    loadingLocationCount = false;
    device: Device;
    options;
    layersControl;
    map: Map;
    nearbyDevices;
    marker;

    chartLocationType;
    permissions: {};

    constructor(private authenticationService: AuthenticationService,
        private cd: ChangeDetectorRef,
        private colors: ColorService,
        private chartService: FhChartService,
        private toastr: ToastrService,
        private http: HttpClient,
        private deviceService: DeviceService,
        private route: ActivatedRoute,
        private router: Router,
        private authentication: AuthenticationService,
        private translateService: TranslateService) {

        this.device = null;

        this.progress[0] = 0;
        this.progress[1] = 0;
        this.progress[2] = 0;
        this.progress[3] = 0;
        this.progress[4] = 0;
        this.progress[5] = 0;

        this.initMap();
    }

    checkPositive(theNumber) {
        if (theNumber === Infinity) {
            return '^ ';
        }
        if (theNumber > 0) {
            return '+' + theNumber + '%';
        } else {
            return theNumber.toString() + '%';
        }
    }

    setDeviceDetails(device: Device): any {
        if (device == null) {
            this.router.navigate(['/Devices/Overview'])
        } else {

            const theData = [{
                name: 'Location type',
                innerSize: '50%',
                data: [{
                    name: 'Ignition off',
                    y: 61.41,
                    sliced: false,
                    selected: false
                }, {
                    name: 'Ignition on',
                    y: 11.84
                }, {
                    name: 'Events',
                    y: 10.85
                }]
            }];

            this.chartLocationType = this.chartService.generateDonutChart(theData, '');

            // End mockcharts

            this.device = device;
            this.loadingNote = false;
            // https://github.com/Asymmetrik/ngx-leaflet


            setTimeout(() => {
                if (this.device && this.device.lastPosition) {

                    if (this.marker) {
                        this.map.removeLayer(this.marker);
                    }

                    this.marker = marker([this.device.lastPosition.latitude, this.device.lastPosition.longitude], {
                        icon: icon({
                            iconSize: [25, 41],
                            iconAnchor: [13, 41],
                            iconUrl: 'assets/marker-icon.png',
                            shadowUrl: 'assets/marker-shadow.png'
                        })
                    });

                    this.map.addLayer(this.marker);
                    this.map.flyTo([this.device.lastPosition.latitude, this.device.lastPosition.longitude], 10);
                }
            }, 100);

            console.log('getting locations by device');

            const locationsEnd = moment().add(-1, 'day').toDate().getTime();
            const locationsStart = moment().add(-1, 'month').toDate().getTime();

            this.deviceService.getLocationCount(this.device.id, locationsStart, locationsEnd).subscribe(result => {
                this.generateLocationCount(result);
                this.generateDistanceCount(result);
                this.loadingLocationCount = false;
                this.cd.markForCheck();
            });

            this.deviceService.getIssueCount(this.device.id, locationsStart, locationsEnd).subscribe(result => {
                this.generateIssueCount(result);
                this.loadingIssueCount = false;
                this.cd.markForCheck();
            });

            forkJoin([this.theIssueTypesObservable, this.issuesByDevice])
                .subscribe((response) => {
                    // all issues for device
                    this.generateColumnRangeChart(response[0], response[1], this.translateService);
                    this.loadingIssues = false;
                    this.cd.markForCheck();
                });

            console.log('getting issues by device');
            this.deviceService.getDashboardById(this.device.id).subscribe(result => {

                this.yesterday = moment().add(-1, 'days').toDate().getTime();
                this.dayAfterYesterday = moment().add(-2, 'days').toDate().getTime();
                this.oneWeek = moment().add(-8, 'days').toDate().getTime();
                this.twoWeeks = moment().add(-15, 'days').toDate().getTime();
                this.oneMonth = moment().add(-31, 'days').toDate().getTime();
                this.twoMonths = moment().add(-62, 'days').toDate().getTime();

                this.issuesLastMonthCount = result.issuesLastMonthCount;
                this.issuesLastMonthCountOld = result.issuesLastMonthCountOld;
                this.issuesLastMonthCountPercentage = this.issuesLastMonthCount > 0 ? Math.round(((this.issuesLastMonthCount / this.issuesLastMonthCountOld) - 1) * 100) : 0;
                this.issuesLastWeekCount = result.issuesLastWeekCount;
                this.issuesLastWeekCountOld = result.issuesLastWeekCountOld;
                this.issuesLastWeekCountPercentage = this.issuesLastWeekCount > 0 ? Math.round(((this.issuesLastWeekCount / this.issuesLastWeekCountOld) - 1) * 100) : 0;
                this.locationsLastMonthCount = result.locationsLastMonthCount;
                this.locationsLastMonthCountOld = result.locationsLastMonthCountOld;
                this.locationsLastMonthCountPercentage = this.locationsLastMonthCount > 0 ? Math.round(((this.locationsLastMonthCount / this.locationsLastMonthCountOld) - 1) * 100) : 0;
                this.locationsLastWeekCount = result.locationsLastWeekCount;
                this.locationsLastWeekCountOld = result.locationsLastWeekCountOld;
                this.locationsLastWeekCountPercentage = this.locationsLastWeekCount > 0 ? Math.round(((this.locationsLastWeekCount / this.locationsLastWeekCountOld) - 1) * 100) : 0;
                this.locationsLastWeekCount = result.locationsLastWeekCount;
                this.locationsLastWeekCountOld = result.locationsLastWeekCountOld;
                this.locationsLastWeekCountPercentage = this.locationsLastWeekCount > 0 ? Math.round(((this.locationsLastWeekCount / this.locationsLastWeekCountOld) - 1) * 100) : 0;

                // this.generateIssuePerIssueTypeCharts(issues);

                // Begin mockcharts
                this.progress[0] = 43;
                this.progress[1] = 37;
                this.progress[2] = 56;
                this.progress[3] = 15;
                this.progress[4] = 11;
                this.progress[5] = 8;

                this.loading = false;
                this.cd.markForCheck();
            });
        }
    }

    generateLocationCount(result) {

        const series1 = [];
        const series2 = [];

        // tslint:disable-next-line:forin
        result.forEach(value => {
            series1.push([value[0], value[1]]);
            series2.push([value[0], value[2]]);
        });

        const theData = [{
            name: 'Messages',
            data: series1,
            type: 'areaspline',
            yAxis: 0,
        }];

        const yAxis = [{
            title: {
                text: 'Messages'
            }
        }];

        this.chartLocationCount = this.chartService.generateSplineArea(theData, yAxis);
    }

    generateDistanceCount(result) {

        const series1 = [];
        const series2 = [];

        // tslint:disable-next-line:forin
        result.forEach(value => {
            series1.push([value[0], value[1]]);
            series2.push([value[0], value[2]]);
        });

        const theData = [{
            name: 'Distance',
            data: series2,
            type: 'areaspline',
            yAxis: 0,
        }];

        const yAxis = [{
            title: {
                text: 'Distance in km',
            }
        }];

        this.chartDistanceCount = this.chartService.generateSplineArea(theData, yAxis);
    }


    generateIssueCount(result) {
        const theData = [{
            name: 'IssueCount',
            data: result
        }];

        const yAxis = [{
            title: {
                text: 'Issues'
            }
        }];

        this.chartIssueCount = this.chartService.generateSplineArea(theData, yAxis);
    }

    showDeletedNotification(event: Device) {
        this.toastr.error('Device ' + event.name, 'Deleted', {
            progressBar: true
        });
    }

    updateSchedule(event) {
        // Update the schedule
        console.log('Update the schedule');
        this.renderDateTime = new Date().getTime();
    }

    ngOnInit() {
        this.permissions = this.authentication.permissions;

        this.loading = true;
        this.loadingNote = true;
        this.loadingLocationCount = true;
        this.loadingIssueCount = true;
        this.loadingIssues = true;
        this.device = new Device;
        this.device.id = '';

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            const deviceFromService = this.deviceService.getDeviceById(id).subscribe(device => {
                this.setDeviceDetails(device);
                this.cd.markForCheck();
            });
        });
    }

    saveNote() {
        console.log('Adding note');

        this.loadingNote = true;

        this.deviceService.saveNote(this.device).subscribe(result => {
            this.loadingNote = false;
            this.cd.markForCheck();
        }, error => {
            this.loadingNote = false;
            this.cd.markForCheck();
        });
    }


    saveFlagged() {
        console.log('Adding flagged');

        this.loadingNote = true;

        this.deviceService.saveFlagged(this.device).subscribe(result => {
            console.log(result);
            this.loadingFlagged = false;
            this.cd.markForCheck();
        }, error => {
            this.loadingFlagged = false;
            console.log(error);
            this.cd.markForCheck();
        });
    }

    sortByDate(array) {
        if (!array || array === undefined || array.length === 0) { return null; }

        array.sort((a: any, b: any) => {
            if (a.date < b.date) {
                return -1;
            } else if (a.date > b.date) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }



    generateColumnRangeChart(issueTypes, issues: Issue[], translateService: TranslateService) {

        function increase_brightness(hex, percent) {
            // strip the leading # if it's there
            hex = hex.replace(/^\s*#|\s*$/g, '');

            // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
            if (hex.length === 3) {
                hex = hex.replace(/(.)/g, '$1$1');
            }

            const r = parseInt(hex.substr(0, 2), 16),
                g = parseInt(hex.substr(2, 2), 16),
                b = parseInt(hex.substr(4, 2), 16);

            /* tslint:disable */
            return '#' +
                ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
                ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
                ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
            /* tslint:enable */
        }

        const theData = [{
            data: []
        }];

        const xAxis = [];

        const sensorNameToSeriesIndex: {[sensorName: string]: number; } = {};
        let sensorIndex = 1;
        issues.forEach(issue => {

            const issueType = issueTypes.find(x => x.id === issue.issueType);
            const issueName = issueType && issueType.name;

            if (xAxis.indexOf(issueName) === -1) {
                xAxis.push(issueName);
            }

            let chartColor = this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#D6A282';
            const percentage = 80 - (80 * (issue.severity / 5));

            chartColor = increase_brightness(chartColor, percentage);

            const interval = 1000 * 60 * 60 * 24; // 24 hours in milliseconds
            const theIssueData = {
                x: xAxis.indexOf(issueName),
                low: moment.utc(issue.issueDate).tz(this.authenticationService.getTimeZoneIana()).startOf('day').toDate().getTime(),
                date: moment.utc(issue.issueDate).tz(this.authenticationService.getTimeZoneIana()).toDate().getTime(),
                high: moment.utc(issue.issueDate).tz(this.authenticationService.getTimeZoneIana()).startOf('day').toDate().getTime() + 86400000,
                violationDuration: issue.violationDuration,
                violationLowerThreshold: issue.violationLowerThreshold,
                violationUpperThreshold: issue.violationUpperThreshold,
                color: chartColor,
                issueId: issue.id,
                severity: issue.severity,
                issueType: issue.issueType,
                sensorName: issue.sensorName
            };
            if (!issue.sensorName) {
                theData[0].data.push(theIssueData);
            } else {
                const existingSensorIndexValue = sensorNameToSeriesIndex[issue.sensorName];
                existingSensorIndexValue ? theData[existingSensorIndexValue].data.push(theIssueData)
                                        : theData.push({data: []});
                if (existingSensorIndexValue == null) {
                    sensorNameToSeriesIndex[issue.sensorName] = sensorIndex
                    theData[sensorIndex].data.push(theIssueData);
                    sensorIndex++;
                }
            }
        });

        for (let index = 0; index < theData.length; index++) {
            const element = theData[index];
            if (element.data.length === 0) {
                theData.splice(index, 1)
            }
        }

        if (!theData.find(x => x.data.length !== 0)) {
            this.chartColumnRangeEnabled = false;
            this.loadingNote = false;
            this.cd.markForCheck();
        } else {
            this.chartColumnRangeEnabled = true;
            this.chartColumnRange = this.chartService.generateColumnRangeChart(theData, xAxis, this.translateService);
            this.cd.markForCheck();
        }
    }

    generateIssuePerIssueTypeCharts(issues: Issue[]) {

        // tslint:disable-next-line:prefer-const
        let theData = [];
        const that = this;

        let startDate = moment().subtract(1, 'months').toDate();
        const endDate = new Date();

        while (startDate < endDate) {
            const newStartDate = new Date(startDate.setDate(startDate.getDate() + 1));
            const compareDate = new Date(startDate.setDate(startDate.getDate() - 1));
            // startDate = startDate.;
            const theIssues = issues.filter(x => x.date.getTime() >= compareDate.getTime() && x.date.getTime() <= newStartDate.getTime());

            theData.push([startDate.getTime(), theIssues.length]);
            startDate = newStartDate;
        }

        console.log('End analising chart');
    }

    // Leaflet
    initMap() {
        this.options = {
            layers: [
                tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
            ],
            zoom: 3,
            center: latLng(20, 4)
        };

        this.osm = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { id: 'MapID', attribution: '...' });

        this.options = {
            layers: [this.osm],
            zoom: 2,
            center: L.latLng(24.577100, 55.019531)
        };
    }
    onMapReady(map: Map) {
        this.map = map;

        const baseMaps = {
            'Osm': this.osm,
        };

        const overlayMaps = {
        };

        L.control.layers(baseMaps, overlayMaps, { position: 'topleft' }).addTo(map);
    }

}
