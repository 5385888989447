import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fh-action-selector',
  templateUrl: 'action-selector.template.html',
  providers: []
})

export class ActionSelectorComponent implements OnChanges {
  @Input() filename: string;
  @Input() platforms: any;
  @Input() type: string;
  @Output() setExclusive = new EventEmitter();
  @Output() sendFile = new EventEmitter();
  @Output() setDrivers = new EventEmitter();
  @Output() setDriversGroup = new EventEmitter();
  @Output() setRefId = new EventEmitter();
  @Output() setType = new EventEmitter();
  @Output() setWrapper = new EventEmitter();

  drivers: any[];
  exclusive: boolean;
  wrappers: string[];
  resources: any;
  driversGroups: string[];
  selectedWrapper: string;
  selectedResource: string;
  selectedDriversGroup: string;

  constructor(private translateService: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.platforms) {
      if (Object.keys(changes).length > 1 || (Object.keys(changes).indexOf('type') === -1 && Object.keys(changes).indexOf('filename') === -1)) {
        this.wrappers = Object.keys(this.platforms);
        this.resources = {};
        Object.keys(this.platforms).forEach(platform => {
          this.resources[platform] = [];
          this.platforms[platform].forEach(resource => {
            const driversGroups = [{ name: this.translateService.instant('importDriver.noDriversGroup'), id: null, drivers: [] }];
            if (resource.drvrsgr && Object.keys(resource.drvrsgr).length) {
              Object.keys(resource.drvrsgr).forEach(group => {
                driversGroups.push({
                  name: resource.drvrsgr[group].n,
                  id: resource.drvrsgr[group].id,
                  drivers: resource.drvrsgr[group].drs
                });
              });
            }

            const drivers = [];
            if (resource.drvrs && Object.keys(resource.drvrs).length) {
              Object.keys(resource.drvrs).forEach(driver => {
                drivers.push(resource.drvrs[driver]);
              });
            }

            this.resources[platform].push({
              name: resource.nm,
              id: resource.id,
              drivers: drivers,
              driversGroups: driversGroups
            });
          });
        });

        this.selectedWrapper = this.wrappers.length ? this.wrappers[0] : null;
        this.setWrapper.emit(this.selectedWrapper);
        this.selectedResource = this.resources[this.selectedWrapper].length ? this.resources[this.selectedWrapper][0].name : null;
        this.driversGroups = this.selectedResource ? this.resources[this.selectedWrapper][0].driversGroups : [];
        this.selectedDriversGroup = this.driversGroups.length ? this.driversGroups[0]['name'] : '';
        this.setDriversGroup.emit(this.driversGroups[0]);
      }

      if (Object.keys(changes).length > 1 || Object.keys(changes).indexOf('filename') === -1) {
        this.setDriversGroups('resource');
      }
    }
  }

  setDriversGroups(type: string): void {
    if (type === 'wrapper') {
      this.driversGroups = this.selectedResource ? this.resources[this.selectedWrapper][0].driversGroups : [];
      this.selectedDriversGroup = this.driversGroups.length ? this.driversGroups[0]['name'] : '';
    } else if (type === 'resource') {
      this.resources[this.selectedWrapper].forEach(resource => {
        if (resource.name === this.selectedResource) {
          this.driversGroups = resource.driversGroups;
          this.drivers = resource.drivers;
          this.setRefId.emit(resource.id);
          this.setDrivers.emit(this.drivers);
        }
      });
      this.selectedDriversGroup = this.driversGroups.length ? this.driversGroups[0]['name'] : '';
    } else {
      this.driversGroups.forEach(driversGroup => {
        if (driversGroup['name'] === this.selectedDriversGroup) {
          this.setDriversGroup.emit(driversGroup);
        }
      });
    }
  }
}
