import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from '../../services/report/report.service';

@Component({
  selector: 'fh-rag-report',
  templateUrl: 'rag-report.template.html'
})
export class RagReportComponent implements OnInit {
  data: any[];
  dtOptions: any;

  constructor(
    private translateService: TranslateService,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.reportService.getReportsDone().subscribe(
      result => {
        const list = JSON.parse(JSON.stringify(result));
        list.forEach(item => {
          const hours = Math.round(item.totaloverspeedingtimeinseconds / 60);
          const minutes = item.totaloverspeedingtimeinseconds % 60;
          item.totaloverspeedingtimeinseconds = hours + ':' + minutes;
          item.color = item.score > 3 ? 'red' : item.score > 1 ? 'orange' : 'green';
          Object.keys(item).forEach(key => {
            item[key] = typeof item[key] === 'number' ? item[key].toLocaleString(localStorage.getItem('language') ? localStorage.getItem('language') : 'en') : item[key];
          });
        });
        this.data = list;
      }
    );
    this.reportService.getReports();
    this.translateService.get('general.date').subscribe(value => {
      this.dtOptions = {
        ordering: false,
        language: {
          lengthMenu: this.translateService.instant('grid.lengthmenu'),
          zeroRecords: this.translateService.instant('grid.empty'),
          info: this.translateService.instant('grid.info'),
          infoEmpty: this.translateService.instant('grid.infoempty'),
          infoFiltered: this.translateService.instant('grid.infofiltered'),
          search: this.translateService.instant('grid.search'),
          infoThousands: '.',
          loadingRecords: this.translateService.instant('grid.loadingrecords'),
          paginate: {
            first: this.translateService.instant('grid.first'),
            last: this.translateService.instant('grid.last'),
            next: this.translateService.instant('grid.next'),
            previous: this.translateService.instant('grid.previous'),
          },
          aria: {
            sortAscending: this.translateService.instant('grid.sortasc'),
            sortDescending: this.translateService.instant('grid.sortdesc')
          }
        }
      };
    });
  }
}
