import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { tileLayer, latLng, circle, polygon, Map, marker, icon, Marker } from 'leaflet';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { trigger, style, animate, transition } from '@angular/animations';
import { TripService } from '../../services/trip/trip.service';
import * as L from 'leaflet';

@Component({
    selector: 'fh-device-trips',
    templateUrl: 'deviceTrips.template.html',
    animations: [
        trigger(
            'enterAnimation', [
                transition(':enter', [
                    // css styles at start of transition
                    style({ opacity: 0 }),

                    // animation and styles at end of transition
                    animate('0.5s', style({ opacity: 1 }))
                ]),
            ]
        )
    ],
})
export class DeviceTripsViewComponent implements OnInit, OnDestroy {
    loading: boolean;
    tripPolyLine;
    selectedTrip: any;
    osm: L.TileLayer;
    googleMaps: L.TileLayer;
    googleHybrid: L.TileLayer;
    leafletView: any;
    heatmapLayer: any;
    markers: any;
    zone: any;
    menuState: string;
    map: any;
    marker: Marker;
    options;
    timeoutHandler;
    trips: any[];
    sub;
    device: Device;
    layersControl;

    // Pagination
    totalItems = 0;
    currentPage = 1;
    smallnumPages = 0;

    constructor(private tripService: TripService, private http: HttpClient, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router) {
        this.device = null;
        this.trips = [];

        this.initMap();
    }

    generateTrips(id) {
        this.tripService.getTripsForDevice(id).subscribe(trips => {
            this.trips = trips;
        })
    }

    ngOnInit() {
        this.device = new Device;
        this.device.id = ''
        this.loading = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.generateTrips(id);
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }

                this.loading = false;
            });
        });
    }

    ngOnDestroy() {
        if (this.timeoutHandler) {
            console.log('Distroy timeouthandler');
            clearInterval(this.timeoutHandler);
        }
    }
    // Map

    // Leaflet
    initMap() {
        this.osm = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '...' });
        this.googleMaps = tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            detectRetina: true
        });
        this.googleHybrid = tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            detectRetina: true
        });

        this.options = {
            layers: [this.osm],
            zoom: 2,
            center: L.latLng(24.577100, 55.019531)
        };
    }


    onMapReady(map: Map) {
        this.map = map;
        const that = this;

        const baseMaps = {
            'Osm': this.osm,
            'Google maps': this.googleMaps,
            'Google hybrid': this.googleHybrid
        };

        const overlayMaps = {
        };

        this.map.on('click', () => {
            this.zone.run(() => {
                this.menuState = 'out';
            });
        });

        L.control.layers(baseMaps, overlayMaps, { position: 'topleft' }).addTo(map);

    }

    centerMap() {
        // do nothing
    }

    drawTrip(trip) {
        console.log(trip);
        this.selectedTrip = trip;
        const pointList = [];

        if (this.tripPolyLine) { this.map.removeLayer(this.tripPolyLine); }

        this.selectedTrip.locations.forEach(location => {
            pointList.push(new L.LatLng(location.Latitude, location.Longitude));
        });

        this.tripPolyLine = new L.Polyline(pointList, {
            color: '#2490EA',
            weight: 4,
            opacity: 0.9,
            smoothFactor: 1
        });
        this.tripPolyLine.addTo(this.map);

        const bounds = this.tripPolyLine.getBounds();

        if (bounds.isValid()) {
            this.map.fitBounds(bounds, { padding: [15, 15] });
        }
    }


    drawLocation(location) {
        setTimeout(() => {
            if (location) {

                if (this.marker) {
                    this.map.removeLayer(this.marker);
                }

                this.marker = marker([location.latitude, location.longitude], {
                    icon: icon({
                        iconSize: [25, 41],
                        iconAnchor: [13, 41],
                        iconUrl: 'assets/marker-icon.png',
                        shadowUrl: 'assets/marker-shadow.png'
                    })
                });

                this.map.addLayer(this.marker);
                this.map.flyTo([location.latitude, location.longitude], 6);
            }
        }, 100);
    }

    // Pagination
    setPage(pageNo: number): void {
        this.currentPage = pageNo;
    }

    pageChanged(event: any): void {
        console.log('Page changed to: ' + event.page);
        console.log('Number items per page: ' + event.itemsPerPage);
    }
}
