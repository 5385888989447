import { Component, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs/Subject';
declare var jQuery: any;

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'iboxseach',
    templateUrl: 'iboxsearch.template.html'
})
export class IboxSearchComponent {
    searchVar: string;
    isEditMode = false;
    isColumnChooseMode = false;
    search$ = new Subject<string>();
    @Output() public seachChanged: EventEmitter<any> = new EventEmitter();

    constructor() {
        this.searchVar = '';

        this.search$
            .debounceTime(700)
            .distinctUntilChanged()
            .subscribe(search => {
                this.seachChanged.emit(search);
            });
    }

    sumbitSearch() {
        this.seachChanged.emit(this.searchVar);
    }

    switchEditMode() {
        this.isEditMode = !this.isEditMode;
        const toolbox = document.getElementById('grid-toolbox');

        if (this.isEditMode) {
            toolbox.classList.remove('hidden');
        } else {
            toolbox.classList.add('hidden');
        }
    }

    switchColumnChooser() {
        this.isColumnChooseMode = !this.isColumnChooseMode;

        const chooser = document.getElementById('grid-column-chooser');

        if (this.isColumnChooseMode) {
            chooser.classList.remove('hidden');
        } else {
            chooser.classList.add('hidden');
        }
    }
}
