import { Routes } from '@angular/router';

import { Error404Component } from './views/appviews/error404.component';

import { DashboardComponent } from './views/appviews/dashboard.component';
import { SearchViewComponent } from './views/appviews/search.component';


// Components
import { LoginComponent } from './views/appviews/login.component';
import { RegisterComponent } from './views/appviews/register.component';

import { BlankLayoutComponent } from './views/shared/layouts/blankLayout.component';
import { BasicLayoutComponent } from './views/shared/layouts/basicLayout.component';
import { AuthGuard } from './auth-guard';


// Device types

import { AboutViewComponent } from './views/appviews/about.component';
import { HelpViewComponent } from './views/appviews/help.component';

import { ProfileComponent } from './views/settings/profile.component';
import { ForgotComponent } from './views/appviews/forgotPassword.component';

// Authentication (SSO)
import { AuthCallbackComponent } from './views/appviews/sso-auth-callback.component';
import { EndsessionCallbackComponent } from './views/appviews/sso-endsession-callback.component';
import { SsoLoginComponent } from './views/appviews/sso-login.component';
import { ReportingComponent } from './views/reporting/reporting.component';
import { DeviceDetailsViewComponent } from './views/devices/deviceDetails.component';
import { DeviceLocationsViewComponent } from './views/devices/deviceLocations.component';
import { DeviceFuelViewComponent } from './views/devices/deviceFuel.component';
import { DeviceTripsViewComponent } from './views/devices/deviceTrips.component';
import { DeviceLogViewComponent } from './views/devices/deviceLog.component';
import { DevicesViewComponent } from './views/devices/devices.component';
import { DevicesMap2ViewComponent } from './views/devices/devicesMap2.component';
import { PassengerImportComponent } from './views/passenger-import/passenger-import.component';
import { RagReportComponent } from './views/rag-report/rag-report.component';
import { DriverImportComponent } from './views/driver-import/driver-import.component';

export const ROUTES: Routes = [
  // Main redirect
  { path: '', redirectTo: 'PassengerImport', pathMatch: 'full' },

  // App views
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      // { path: 'Dashboard', component: DashboardComponent },
      { path: 'Settings/Profile', component: ProfileComponent },
      // { path: 'Reporting', component: ReportingComponent },
      // { path: 'RagReport', component: RagReportComponent },
      // { path: 'Devices', component: DevicesViewComponent },
      { path: 'PassengerImport', component: PassengerImportComponent },
      { path: 'DriverImport', component: DriverImportComponent },
      // { path: 'Devices/Overview', component: DevicesViewComponent }
    ]
  },
  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'Login', component: LoginComponent },
      { path: 'Register', component: RegisterComponent },
      { path: 'ForgotPassword', component: ForgotComponent },
    ]
  },
  // {
  //   path: '', component: BasicLayoutComponent,
  //   canActivate: [AuthGuard],
  //   canActivateChild: [AuthGuard],
  //   children: [
  //     { path: 'Devices/Map2', component: DevicesMap2ViewComponent },
  //   ]
  // },
  // {
  //   path: '', component: BasicLayoutComponent,
  //   canActivate: [AuthGuard],
  //   canActivateChild: [AuthGuard],
  //   children: [
  //     { path: 'DeviceDetails/Index/:id', component: DeviceDetailsViewComponent },
  //     { path: 'DeviceDetails/Locations/:id', component: DeviceLocationsViewComponent },
  //     { path: 'DeviceDetails/Fuel/:id', component: DeviceFuelViewComponent },
  //     { path: 'DeviceDetails/Trips/:id', component: DeviceTripsViewComponent },
  //     { path: 'DeviceDetails/Log/:id', component: DeviceLogViewComponent }]
  // },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'Error404', component: Error404Component },
    ]
  }, {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'About', component: AboutViewComponent },
    ]
  }, {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'Help', component: HelpViewComponent },
    ]
  }, {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'Auth-callback', component: AuthCallbackComponent },
      { path: 'Endsession-callback', component: EndsessionCallbackComponent },
      { path: 'SSOLogin', component: SsoLoginComponent },
    ],
  },
  // Handle all other routes
  { path: '**', redirectTo: 'Error404' }
];
