import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';
import { of } from 'rxjs/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';

import { AuthenticationService } from '../authentication/authentication.service';
import { Device, DeviceDashboard } from '../../models/device.model';
import { Location } from '../../models/location.model';
import { LoggingService } from '../logging/logging.service';
import { DeviceType } from '../../models/devicetype.model';
import { Manufacturer } from '../../models/manufacturer.model';
import { LocationCount } from '../../models/dashboard.model';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';

@Injectable()
export class DeviceService {
    token: string;
    timestamp;
    url = '';
    Devices: Device[] = [];

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('device');
        this.token = this.authenticationService.getAuthToken();
    }

    getPagingUrl() {
        return this.url + 'PagedDevices';
    }

    getPagingWithIssuesUrl() {
        return this.url + 'PagedDevicesWithIssues';
    }

    getPagingWithIssueTypesUrl() {
        return this.url + 'PagedDevicesWithIssueTypes';
    }

    getDevices(setDefinate = true, onlyFromMemmory = false): Observable<Device[]> {

        if (this.Devices.length !== 0) {
            return of(this.Devices);
        }

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting devices from service');
        return this.http.get(this.url, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                if (setDefinate) {
                    console.log('setting Devices');
                    this.Devices = parsedResponse;
                }
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getDevicesWithIssues(start, end): Observable<Device[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting devices from service');
        return this.http.get(this.url + 'GetDevicesWithIssues/?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000), { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getDeviceById(id: string): Observable<Device> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('Fetch device by id ' + id);
        return this.http.get(this.url + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseReponseDetails(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    resetDeviceCache(): Observable<boolean> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.get(this.url + 'ResetCache', { headers: headers })
            .map((data: any) => {
                return data;
            })
            .catch(this.handleError);
    }

    saveNote(device: Device): Observable<Boolean> {

        const post = { Note: device.note, isNoteWarning: device.isNoteWarning };
        const body = JSON.stringify(post);

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.post(this.url + device.id + '/Note', body, { headers: headers })
            .map(result => {
                console.log(result);
                return true;
            }).catch(this.handleError);
    }

    saveFlagged(device: Device): Observable<Boolean> {

        const post = { IsFlagged: device.isFlagged };
        const body = JSON.stringify(post);

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.post(this.url + device.id + '/Flagged', body, { headers: headers })
            .map(result => {
                console.log(result);
                return true;
            }).catch(this.handleError);
    }

    saveIssueCalculation(device: Device): Observable<Boolean> {

        const post = { HasIssueCalculationEnabled: device.hasIssueCalculationEnabled };
        const body = JSON.stringify(post);

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.post(this.url + device.id + '/IssueCalculation', body, { headers: headers })
            .map(result => {
                console.log(result);
                return true;
            }).catch(this.handleError);
    }
    // Other properties

    getDashboardById(id: string): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('Fetch device by id ' + id);
        return this.http.get(this.url + id + '/Dashboard', { headers: headers })
            .map((data) => {
                return data;
            })
            .catch(this.handleError);
    }


    getDeviceSensorsById(id: string): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('Fetch device sensors by id ' + id);
        return this.http.get(this.url + id + '/Sensors/', { headers: headers })
            .map((data) => {
                return data;
            })
            .catch(this.handleError);
    }

    getDevicesByDeviceType(id: string) {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting devices from service');
        return this.http.get(this.url + 'deviceType/' + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getDevicesByCustomer(id: string) {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting devices from service');
        return this.http.get(this.url + 'customer/' + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getDevicesByAccountPlatform(id: string) {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting devices from service');
        return this.http.get(this.url + 'accountplatform/' + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getLocationCount(id: string, start, end): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting dashboard locationCount from service per devicetype');
        return this.http.get(this.url + id + '/GetMessageCountByDay/?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000), { headers: headers })
            .map((data: any) => {
                return Object.keys(data).map(k => [(parseFloat(k) * 1000), data[k].item1, data[k].item2]);
            })
            .catch(this.handleError);
    }

    getIssueCount(id: string, start, end): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting dashboard locationCount from service per devicetype');
        return this.http.get(this.url + id + '/GetIssueCountByDay/?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000), { headers: headers })
            .map((data: any) => {
                return Object.keys(data).map(k => [(parseFloat(k) * 1000), data[k]]);
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        console.log(error);
        return throwError(error.statusText);
    }

    parseResponse(json: any): Device[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Devices.');

        const ident = 1;
        const devices: Device[] = [];

        json.forEach(item => {

            const device = this.parseReponseDetails(item);

            devices.push(device);
        });

        this.timestamp = new Date().getTime();
        return devices;
    }

    parseReponseDetails(item) {
        //   this.loggingService.log(this.constructor().name + " - New", item);
        const device = new Device();
        device.id = item.id;
        device.createdDate = item.createdDate ? moment(item.createdDate.replace(' ', '')).toDate() : new Date();
        device.name = item.name;
        device.customFields = item.customFields;
        device.lastCommunication = item.lastCommunication ? moment(item.lastCommunication.replace(' ', '')).toDate() : new Date();
        device.modifiedDate = item.modifiedDate ? moment(item.modifiedDate.replace(' ', '')).toDate() : new Date();
        device.endDate = item.endDate ? moment(item.endDate.replace(' ', '')).toDate() : null;
        device.isEnded = device.endDate != null && device.endDate.getTime() < new Date().getTime();
        device.phone = item.phone;
        device.referenceId = item.referenceId;
        device.unitId = item.unitId;
        device.unitId2 = item.unitId2;
        device.modelName = item.modelName;
        device.modelId = item.modelId;
        device.modelThumb = item.modelThumb;
        device.manufacturer = item.manufacturer;
        device.manufacturerName = item.manufacturerName;
        device.sensors = item.sensors;
        device.issueCount = item.issueCount;

        device.platformId = item.platformId;
        device.platformName = item.platformName;

        device.accountPlatformId = item.accountPlatformId;
        device.accountPlatformName = item.accountPlatformName;

        device.customerId = item.customerId;
        device.customerName = item.customerName;

        device.note = item.note;
        device.isNoteWarning = item.isNoteWarning;

        device.isFlagged = item.isFlagged;

        device.hasIssueCalculationEnabled = item.hasIssueCalculationEnabled;
        device.firmware = item.firmware;

        if (item.lastPosition && item.lastPosition.indexOf(',') > -1) {
            const latlngStr = item.lastPosition.split(',', 2);
            const lat = parseFloat(latlngStr[0]);
            const lng = parseFloat(latlngStr[1]);

            device.lastPosition = item.lastPosition ? new Location(latlngStr[1], latlngStr[0]) : null // new Location({ });
        }

        if (item.model) {
            const deviceType = new DeviceType();
            deviceType.id = item.model.id;
            deviceType.name = item.model.name;
            device.deviceName = item.model.name;
            deviceType.code = item.model.code;
            deviceType.createdDate = item.model.createdDate ? moment(item.model.createdDate.replace(' ', '')).toDate() : new Date();
            deviceType.modifiedDate = item.model.modifiedDate ? moment(item.model.modifiedDate.replace(' ', '')).toDate() : new Date();

            if (item.model.manufacturer) {
                const manufacturer = new Manufacturer();
                manufacturer.id = item.model.manufacturer.id
                manufacturer.name = item.model.manufacturer.name
                device.manufacturerName = manufacturer.name;
                manufacturer.createdDate = moment(item.model.manufacturer.createdDate.replace(' ', '')).toDate();
                manufacturer.modifiedDate = moment(item.model.manufacturer.modifiedDate.replace(' ', '')).toDate();
                deviceType.manufacturer = manufacturer;
            }

            device.deviceType = deviceType;
        }

        return device;
    }
}
