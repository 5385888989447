import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fh-driver-list',
  templateUrl: 'driver-list.template.html',
  providers: []
})

export class DriverListComponent implements OnChanges {
  @Input() csvHeaders: string[];
  @Input() updateHeaders: string[];
  @Input() driversGroup: any;
  @Input() drivers: any[];
  @Input() editedDrivers: number[];
  @Input() deletedDrivers: number[];
  @Input() savedDrivers: number[];
  @Input() records: any[];
  @Input() allRecords: any[];
  @Input() uniqueValues: any[];
  @Input() type: string;
  dtOptions: any;
  openTable = true;

  constructor(private translateService: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).indexOf('updateHeaders') !== -1) {
      this.openTable = false;
      setTimeout(() => {
        this.openTable = true;
      }, 1);
    }

    if ((Object.keys(changes).indexOf('csvHeaders') !== -1 && this.type === 'add') || (Object.keys(changes).indexOf('type') !== -1 && this.type !== 'add')) {
      this.setDtOptions();
    }
  }

  groupData(header: string): void {
    this.openTable = false;
    if (this.uniqueValues[header]) {
      const selectedHeaders = [];
      this.uniqueValues[header].headers.forEach(head => {
        if (head.active) {
          selectedHeaders.push(head.name);
        }
      });
      this.allRecords.forEach(record => {
        if (selectedHeaders.indexOf(record[header]) === -1) {
          this.records.splice(this.records.indexOf(record), 1);
        }
      });
    }
    setTimeout(() => {
      this.openTable = true;
    }, 1);
  }

  setDtOptions(): void {
    this.openTable = false;
    this.dtOptions = {
      language: {
        lengthMenu: this.translateService.instant('grid.lengthmenu'),
        zeroRecords: this.translateService.instant('grid.empty'),
        info: this.translateService.instant('grid.info'),
        infoEmpty: this.translateService.instant('grid.infoempty'),
        infoFiltered: this.translateService.instant('grid.infofiltered'),
        search: this.translateService.instant('grid.search'),
        infoThousands: '.',
        loadingRecords: this.translateService.instant('grid.loadingrecords'),
        paginate: {
          first: this.translateService.instant('grid.first'),
          last: this.translateService.instant('grid.last'),
          next: this.translateService.instant('grid.next'),
          previous: this.translateService.instant('grid.previous'),
        },
        aria: {
          sortAscending: this.translateService.instant('grid.sortasc'),
          sortDescending: this.translateService.instant('grid.sortdesc')
        }
      }
    };
    if (this.type === 'add' && this.csvHeaders && this.csvHeaders.length) {
      const columns = [];
      this.csvHeaders.forEach(header => {
        columns.push({
          orderable: header === 'Name'
        });
      });
      this.dtOptions['columns'] = columns;
    }
    setTimeout(() => {
      this.openTable = true;
    }, 1);
  }

  toggleGroupDropdown(header: string) {
    Object.keys(this.uniqueValues).forEach(head => {
      this.uniqueValues[head].open = !this.uniqueValues[header] || header !== head ? false : !this.uniqueValues[header].open;
    });
  }
}
