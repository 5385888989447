import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fh-modal-select-key',
  templateUrl: 'modal-select-key.template.html',
  providers: []
})

export class ModalSelectKeyComponent {
  @Input() headers: string[];
  @Input() show: boolean;
  @Output() closeModal = new EventEmitter();
  @Output() setPrimaryKey = new EventEmitter();

  constructor() { }
}
