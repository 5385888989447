import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class ReportService {
  headers: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('passenger_token') }) };
  report: any[] = [
    {
      drivername: 'Wouter de Korte',
      totaldistanceinmeter: 800345,
      totalaccelcounts: 24,
      accelerationsper100km: 3,
      totaldecelcounts: 87,
      decelarationsper100km: 6,
      highestspeedkmh: 141,
      totaloverspeedingtimeinseconds: 23567,
      speedforeach10secperkm: 45,
      score: 1
    },
    {
      drivername: 'Wouter de Korte',
      totaldistanceinmeter: 800345,
      totalaccelcounts: 24,
      accelerationsper100km: 3,
      totaldecelcounts: 87,
      decelarationsper100km: 6,
      highestspeedkmh: 141,
      totaloverspeedingtimeinseconds: 23567,
      speedforeach10secperkm: 45,
      score: 3
    },
    {
      drivername: 'Wouter de Korte',
      totaldistanceinmeter: 800345,
      totalaccelcounts: 24,
      accelerationsper100km: 3,
      totaldecelcounts: 87,
      decelarationsper100km: 6,
      highestspeedkmh: 141,
      totaloverspeedingtimeinseconds: 23567,
      speedforeach10secperkm: 45,
      score: 6
    }
  ];
  reportsDone = new Subject<any>();

  constructor(private http: HttpClient) { }

  getReportsDone(): Observable<any> {
    return this.reportsDone.asObservable();
  }

  getReports(): void {
    this.reportsDone.next(this.report);
  }
}
