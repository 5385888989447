import { Component } from '@angular/core';
import { NgModule, HostListener } from '@angular/core'
import { Http } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { appDate, appVersion } from '../../app.version'
import { FormsModule } from '@angular/forms';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { UserService } from '../../services/users/user.service';
import { TweenMax } from 'gsap';

import { AuthenticateResult } from '../../models/authenticateresult.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from '../../auth-guard';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { environment } from 'environments/environment';
import { isUndefined } from 'util';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { BsLocaleService } from 'ngx-bootstrap';
window['moment'] = Moment;
mTZ()

@Component({
  templateUrl: './login.template.html',
  styleUrls: ['./login.template.css'],
})
export class LoginComponent {

  appVersion: string;
  UserName = '';
  Password = '';
  error: string;
  data: string;
  returnUrl;
  subscription;
  param = null;
  redirect = '';
  loading = false;
  errorLoggedIn = false;
  errorExtended: string;
  ssoEnabled = false;

  constructor(private localeService: BsLocaleService, private translate: TranslateService, private route: ActivatedRoute, private router: Router, private http: Http, private authenticationService: AuthenticationService, private userService: UserService, private authGuard: AuthGuard, private socialAuthService: AuthService) {
    const that = this;
    // this.ssoEnabled = environment.SSOEnabled;

    this.subscription = route.queryParams.subscribe((queryParam: any) => {
      that.param = queryParam['prev'];
      that.redirect = queryParam['redirect'];
    });

    this.appVersion = environment.version;
  }

  onMouseMove(event) {

    const e = event;
    // Mouse Move 3d Effect

    const perX = (e.clientX / $(window).width()) - 0.5;
    const perY = (e.clientY / $(window).height()) - 0.5;
    // TweenMax.to('.effect-3d-element', 0.4, { rotationY: 5 * perX, rotationX: 5 * perY, transformPerspective: 1000, transformOrigin: 'center' })
  }

  clicked(event) {
    console.log('Clicked login -> Authenticating');
    this.loading = true;

    this.authenticationService
      .performLogin(this.UserName, this.Password)
      .subscribe((result) => {

        if (result !== null) {
          this.authenticationService.getToolsAuth(this.UserName, this.Password)
            .subscribe(auth => {
              localStorage.setItem('passenger_token', auth['Token']);
              this.loading = false;
              this.loginCompleted(result);
            });
        } else {
          this.loading = false;
          this.error = ('Error while logging in');
        }

      }, (error) => {
        this.errorLoggedIn = true;
        console.log(error);
        if (error.status === 401) {
          this.error = ('Error while logging in');
        } else {
          this.error = ('Unable to connect to api');
        }

        this.errorExtended = error.message + ' : ' + error.statusText;
        this.loading = false;
      });
  }

  private logError(err) {
    console.error('There was an error: ' + err);
  }

  private loginCompleted(authenticateResult: AuthenticateResult) {
    this.loading = true;

    if (this.translate.currentLang !== authenticateResult.user.language) {
      console.log('Use user language ' + authenticateResult.user.language);
      this.translate.use(authenticateResult.user.language);
      Moment.locale(authenticateResult.user.language);
      this.localeService.use(authenticateResult.user.language);
    }

    const newUrl = '/PassengerImport';
    // const newUrl = this.redirect;
    this.redirect ? this.router.navigate([newUrl]) : this.router.navigate(['/PassengerImport']);
    /*
            this.userService.getUser()
                .subscribe((result) => {
                    if (result) {
                        console.log('2 loginCompleted ' + this.param);
                        this.router.navigate(['/']);
                    }
                }, (error) => {
                    this.error = error;
                    console.log(error);
                    setTimeout(() => {
                        this.router.navigate(['/']);
                    }, 3000);
                });
                */
  }
  public socialSignIn(socialPlatform: string) {
    this.loading = true;
    let socialPlatformProvider;
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
      if (isUndefined(userData.email) && userData.provider === 'FACEBOOK') {
        this.error = 'Please try again and provide your email this time';
        this.loading = false;
      } else {
        this.authenticationService
          .performLoginWithSSO(userData.email, userData.provider, userData.authToken)
          .subscribe((result) => {
            this.loading = false;
            if (result !== null) {
              this.loginCompleted(result);
            } else {
              this.error = ('Error while logging in');
            }

          }, (error) => {
            this.errorLoggedIn = true;
            if (error.status === 401) {
              this.error = ('Error while logging in');
            } else if (error.status === 404 && error.error === 'no user') {
              this.error = (localStorage.getItem(AuthenticationService.ID) + ' is not recognized. Please login with a different account.');
            } else {
              this.error = ('Unable to connect to api');
            }

            this.errorExtended = error.message + ' : ' + error.statusText;
            this.loading = false;
          });
      }
      this.loading = false;

    }
    ).catch(error => {
      console.log('error while trying to login')
      this.loading = false;
    });
  }
}

