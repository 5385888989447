import { User } from './user.model';
import { Device } from './device.model';
import { Issue } from './issue.model';

export class AuditLog {

    public id: string;
    public user: string;
    public device: Device;
    public issue: Issue;
    public timestamp: Date;
    public description: string;
    public logtype: any;
}
