import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'fh-update-params',
  templateUrl: 'update-params.template.html',
  providers: []
})

export class UpdateParamsComponent implements OnChanges {
  @Input() params: any[];
  @Input() passengers: boolean;
  @Input() headers: string[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void { }

  addRow(): void {
    this.params.push({
      all: false,
      keyF: '',
      keyV: '',
      replaceF: '',
      replaceV: ''
    });
  }

  removeRow(i: number): void {
    this.params.splice(i, 1);
  }
}
