import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { DataTablesModule } from 'angular-datatables';

import { DashboardComponent } from './appviews/dashboard.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'


// Calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './appviews/login.component';
import { RegisterComponent } from './appviews/register.component';

// Modules
import { ChartModule } from 'angular-highcharts';
import { HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { PrettyJsonModule } from '../modules/prettyjson/prettyjson.module';

// Leaflet
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

// Color picker
import { ColorPickerModule } from 'ngx-color-picker';
import { TranslateModule } from '@ngx-translate/core';

// NGX-Bootstrap
import { BsDatepickerModule, ModalModule } from 'ngx-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

// Modules
import { ICheckModule } from '../modules/icheck/icheck.module';
import { WizardModule } from '../modules/wizard/wizard.module';

import { CountoModule } from '../modules/counto/counto.module';
import { AboutViewComponent } from './appviews/about.component';
import { HelpViewComponent } from './appviews/help.component';
import { TimezonePickerModule } from 'ng2-timezone-selector';
// IboxTools
import { IboxToolsModule } from './shared/iboxtools/iboxtools.module';
import { UsercontrolsModule } from './shared/usercontrols.module';
import { ForgotComponent } from './appviews/forgotPassword.component';

// Authentication callback components
import { EndsessionCallbackComponent } from './appviews/sso-endsession-callback.component';
import { AuthCallbackComponent } from './appviews/sso-auth-callback.component';
import { SsoLoginComponent } from './appviews/sso-login.component';

// Devices
import { DevicesViewComponent } from './devices/devices.component';
import { DeviceDetailsViewComponent } from './devices/deviceDetails.component';
import { DeviceFuelViewComponent } from './devices/deviceFuel.component';
import { DeviceLocationsViewComponent } from './devices/deviceLocations.component';
import { DeviceTripsViewComponent } from './devices/deviceTrips.component';
import { DeviceLogViewComponent } from './devices/deviceLog.component';

// Driver import
import { ActionSelectorComponent } from './driver-import/action-selector.component';
import { DriverImportComponent } from './driver-import/driver-import.component';
import { DriverImportFunctionalityComponent } from './driver-import/driver-import-functionality.component';
import { DriverFieldMapperComponent } from './driver-import/driver-field-mapper.component';
import { DriverListComponent } from './driver-import/driver-list.component';
import { DriverUpdateParamsComponent } from './driver-import/driver-update-params.component';
import { DriverDeleteParamsComponent } from './driver-import/driver-delete-params.component';
import { ModalCombineHeadersComponent } from './driver-import/modal-combine-headers.component';
import { ModalSelectKeyComponent } from './driver-import/modal-select-key.component';

// Passenger import
import { DeleteParamsComponent } from './passenger-import/delete-params.component';
import { PassengerImportComponent } from './passenger-import/passenger-import.component';
import { PassengerListComponent } from './passenger-import/passenger-list.component';
import { FieldMapperComponent } from './passenger-import/field-mapper.component';
import { FileUploadComponent } from './passenger-import/file-upload.component';
import { UpdateParamsComponent } from './passenger-import/update-params.component';

// RAG Report
import { RagReportComponent } from './rag-report/rag-report.component';

import { ProfileComponent } from './settings/profile.component';
import { Error404Component } from './appviews/error404.component';
import { ReportingComponent } from './reporting/reporting.component';
import { CombineHeadersComponent } from './reporting/combine-headers-modal.component';
import { SelectColumnModalComponent } from './reporting/select-column-modal.component';
import { DevicesMap2ViewComponent } from './devices/devicesMap2.component';
// Pipes
import { FilterRelevantPipe } from 'app/common/relevant.pipe';
import { TextFilterPipe } from 'app/common/textFilter.pipe';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MomentModule } from 'angular2-moment';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotComponent,
    DashboardComponent,
    DevicesViewComponent,
    DeviceDetailsViewComponent,
    DeviceFuelViewComponent,
    DeviceLocationsViewComponent,
    DevicesMap2ViewComponent,
    ReportingComponent,
    CombineHeadersComponent,
    SelectColumnModalComponent,
    DeviceTripsViewComponent,
    DeviceLogViewComponent,
    DeleteParamsComponent,
    DriverImportComponent,
    DriverImportFunctionalityComponent,
    DriverFieldMapperComponent,
    ActionSelectorComponent,
    DriverListComponent,
    DriverUpdateParamsComponent,
    DriverDeleteParamsComponent,
    ModalCombineHeadersComponent,
    ModalSelectKeyComponent,
    PassengerImportComponent,
    PassengerListComponent,
    FieldMapperComponent,
    FileUploadComponent,
    UpdateParamsComponent,
    RagReportComponent,
    AboutViewComponent,
    HelpViewComponent,
    ProfileComponent,
    Error404Component,
    EndsessionCallbackComponent,
    AuthCallbackComponent,
    SsoLoginComponent,
    FilterRelevantPipe,
    TextFilterPipe
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AngularMultiSelectModule,
    ChartModule,
    DataTablesModule,
    HttpClientModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CountoModule,
    IboxToolsModule,
    TimezonePickerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ICheckModule.forRoot(),
    WizardModule,
    SortableModule.forRoot(),
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    LeafletModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    UsercontrolsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(255,255,255,0.7)',
      backdropBorderRadius: '4px',
      primaryColour: '#DC8139',
      secondaryColour: '#DC8139',
      tertiaryColour: '#DC8139'
    }),
    ColorPickerModule,
    TranslateModule,
    PrettyJsonModule
  ],
  exports: [
    DashboardComponent,
    LoginComponent,
    TranslateModule
  ],
})

export class AppviewsModule {
}
