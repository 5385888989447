import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'fh-combine-headers-modal',
  templateUrl: 'combine-headers-modal.template.html'
})
export class CombineHeadersComponent implements OnChanges {
  @Input() newFile: any;
  @Input() leadingFile: any;
  @Output() combine = new EventEmitter<any>();
  newHeader: string;
  leadingHeader: string;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.newHeader = changes.newFile.currentValue.headers[0];
    this.leadingHeader = changes.leadingFile.currentValue.headers[0];
  }

  combineFiles(): void {
    this.combine.emit([this.newHeader, this.leadingHeader]);
  }
}
