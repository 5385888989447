import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ImportService {
  headers: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('passenger_token') }) };

  resources: any = [];
  resourcesDone = new Subject<any>();

  tagsGroups: any = {};

  passengersList: any;
  passengersListDone = new Subject<any>();

  drivers: any = {};
  driversDone = new Subject<any>();
  crudDriversDone = new Subject<any>();

  passengersDone = new Subject<any>();

  constructor(private http: HttpClient, private translateService: TranslateService) { }

  getPassengersDone(): Observable<any> {
    return this.passengersListDone.asObservable();
  }

  getPassengers(): void {
    // this.http.get('https://localhost:5001/api/passenger', this.headers)
    this.http.get(environment.ToolsUrl + 'passenger', this.headers)
      .subscribe((result: any) => {
        console.log(result);
        this.passengersList = {};
        Object.keys(result).forEach(platform => {
          this.passengersList[platform] = JSON.parse(result[platform]).items;
        });
        this.passengersListDone.next(this.passengersList);
      });
  }

  getDriversDone(): Observable<any> {
    return this.driversDone.asObservable();
  }

  getDrivers(): void {
    if (Object.keys(this.drivers).length) {
      this.driversDone.next(this.drivers);
    } else {
      this.http.get(environment.ToolsUrl + 'driver', this.headers)
        .subscribe((result: any) => {
          this.drivers = {};
          Object.keys(result).forEach(resource => {
            this.drivers[resource] = result[resource].items;
          });
          this.driversDone.next(this.drivers);
        });
    }
  }

  getResourcesDone(): Observable<any> {
    return this.resourcesDone.asObservable();
  }

  getResources(): void {
    if (this.resources.length) {
      this.resourcesDone.next(this.resources);
      return;
    }

    this.http.get(environment.ToolsUrl + 'resources', this.headers)
      .subscribe((result: any) => {
        const resources = {};
        const tagsGroups = {};
        Object.keys(result).forEach(item => {
          resources[item] = {};
          if (Object.keys(JSON.parse(result[item].tagsGroups)).indexOf('error') !== -1) {
            tagsGroups[item] = [];
          } else {
            tagsGroups[item] = this.extractTagsGroups(JSON.parse(result[item].tagsGroups).items);
          }

          resources[item] = result[item].resources;
        });
        this.resourcesDone.next({ resources, tagsGroups });
        this.resources = resources;
        this.tagsGroups = tagsGroups;
      });
  }

  extractTagsGroups(tagsGroups: any[]): any[] {
    const groups = [];
    tagsGroups.forEach(item => {
      Object.keys(item.tagsgr).forEach(tagsGr => {
        if (item.tagsgr[tagsGr].id && item.tagsgr[tagsGr].n) {
          groups.push({
            id: item.tagsgr[tagsGr].id,
            name: item.tagsgr[tagsGr].n,
            description: item.tagsgr[tagsGr].d,
            tags: item.tagsgr[tagsGr].tgs
          });
        }
      });
    });
    return groups;
  }

  addPassengersDone(): Observable<any> {
    return this.passengersDone.asObservable();
  }

  addPassengers(passengers: any[], wrapper: string, resourceId: number, duration: number, tagGroup: any, action: string): void {
    const body = {
      'Action': action,
      'Name': wrapper,
      'Passengers': passengers,
      'ResourceId': resourceId,
      'Art': duration,
      'BindTags': tagGroup && tagGroup.n !== this.translateService.instant('import.noTagsGroups')
    };

    if (tagGroup && tagGroup.n !== this.translateService.instant('import.noTagsGroups')) {
      body['BindTagsParams'] = {
        itemId: resourceId,
        id: tagGroup.id,
        callMode: 'update',
        n: tagGroup.n,
        d: tagGroup.d
      };

      body['Tags'] = tagGroup.tgs;
    }

    this.http.post(environment.ToolsUrl + 'passenger', body, this.headers)
      .subscribe((result: any) => {
        this.passengersDone.next(
          {
            status: result.indexOf('error') === -1 ? 'success' : 'error',
            type: action === 'create' ? 'adding' : action === 'update' ? 'updating' : 'deleting'
          });
        if (action === 'create') {
          this.getPassengers();
        }
      });
  }

  getCrudDriversDone(): Observable<any> {
    return this.crudDriversDone.asObservable();
  }

  crudDrivers(drivers: any[], wrapper: string, resourceId: number, driversGroup: any, action: string): void {
    drivers.forEach(driver => {
      driver.p = driver.p.replace('+', '%2B');
    });

    const body = {
      'Action': action,
      'Name': wrapper,
      'Drivers': drivers,
      'ResourceId': resourceId,
      'BindTags': driversGroup && driversGroup.name !== this.translateService.instant('importDriver.noDriversGroup')
    };

    if (driversGroup && driversGroup.name !== this.translateService.instant('importDriver.noDriversGroup')) {
      body['BindTagsParams'] = {
        itemId: resourceId,
        id: driversGroup.id,
        callMode: 'update',
        n: driversGroup.name,
        d: driversGroup.description ? driversGroup.description : '',
        f: 0
      };

      body['Tags'] = driversGroup.drivers;
    }

    this.http.post(environment.ToolsUrl + 'driver', body, this.headers)
      .subscribe((result: any) => {
        this.crudDriversDone.next(
          {
            status: result.indexOf('error') === -1 ? 'success' : 'error',
            type: action === 'create' ? 'adding' : action === 'update' ? 'updating' : 'deleting'
          });
        if (action === 'create') {
          this.getDrivers();
        }
      });
  }
}
