import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';

import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';

import { DeviceType } from '../../models/devicetype.model';
import { Manufacturer } from '../../models/manufacturer.model';
import { AuditLog } from '../../models/auditlog.model';
import { Issue } from '../../models/issue.model';
import { Device } from '../../models/device.model';
import { UserService } from '../users/user.service';
import { AppUser } from '../../models/user.model';
import { throwError, of } from 'rxjs';

@Injectable()
export class AuditLogService {
    token: string;
    timestamp;
    url = '';
    users: AppUser[] = [];
    timezoneIana: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService, private userService: UserService) {
        this.url = authenticationService.getWebserviceURL('auditlog');
        this.token = authenticationService.getAuthToken();
        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.userService.getUsers().subscribe(users => {
            this.users = users;
        });
    }

    getAuditLogByDevice(id: string): Observable<AuditLog[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting auditlog from service');
        return this.http.get(this.url + 'device/' + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByUser(id: string): Observable<AuditLog[]> {
        const auditlogs: AuditLog[] = [];
        return of(auditlogs);
    }


    getAuditLogByIssue(id: string): Observable<AuditLog[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting auditlog from service');
        return this.http.get(this.url + 'issue/' + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        console.log(error);
        return throwError(error.statusText);
    }

    parseResponse(json: any): AuditLog[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Devices.');

        const ident = 1;
        const auditlogs: AuditLog[] = [];

        json.forEach(item => {

            const log = new AuditLog();
            log.id = item.id;
            log.timestamp = moment.utc(item.createdDate)['tz'](this.timezoneIana);
            log.description = item.description;
            log.logtype = item.logType;

            if (item.user != null) {
                log.user = item.user.userName;
            } else {
                log.user = 'System';
            }

            if (item.device) {
                const device = new Device();
                device.name = item.device.name;
                device.id = item.device.id;
                log.device = device;
            }

            if (item.issue) {
                const issue = new Issue();
                issue.issueType = item.issue.issueType;
                issue.id = item.issue.id;
                issue.date = moment.utc(item.issue.timestamp)['tz'](this.timezoneIana).toDate();
                log.issue = issue;
            }
            auditlogs.push(log);
        });

        this.timestamp = new Date().getTime();
        return auditlogs;
    }
}
