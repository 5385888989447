import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'fh-file-upload',
  templateUrl: 'file-upload.template.html',
  providers: []
})

export class FileUploadComponent implements OnChanges {
  @Output() file = new EventEmitter<any>();
  @Input() type: string;
  @Input() mandatoryHeaders: string[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void { }

  checkDuplicates(passengers: any[], missingHeaders: string[]): any {
    const duplicates = {};
    this.mandatoryHeaders.forEach(header => {
      duplicates[header] = [];
      passengers.forEach((passenger: any, i: number) => {
        passengers.forEach((passenger2: any, i2: number) => {
          if (missingHeaders.indexOf(header) === -1 && passenger[header] === passenger2[header] && i !== i2 && duplicates[header].indexOf(passenger[header]) === -1) {
            duplicates[header].push(passenger[header]);
          }
        });
      });
    });

    return duplicates;
  }

  checkSeperator(text: string): string {
    let commas = 0;
    let semicolons = 0;
    for (let i = 0; i < text.length; i++) {
      if (text[i] === ',') {
        commas += 1;
      } else if (text[i] === ';') {
        semicolons += 1;
      }
    }
    return commas > semicolons ? ',' : ';';
  }

  fileChangeListener($event: any): void {
    let file = {};
    const input = $event.target;
    file['filename'] = $event.srcElement.files.length ? $event.srcElement.files[0].name : null;

    if ($event.srcElement.files.length) {
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = (data) => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const seperator = this.checkSeperator(csvRecordsArray[0]);
        file['csvHeaders'] = this.getHeaderArray(csvRecordsArray, seperator);
        file['csvRecords'] = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, file['csvHeaders'], seperator);
        file['selectedRecords'] = JSON.parse(JSON.stringify(file['csvRecords']));
        if (this.type !== 'update') {
          file['missingHeaders'] = [];
          this.mandatoryHeaders.forEach(header => {
            if (file['csvHeaders'].indexOf(header) === -1) {
              file['missingHeaders'].push(header);
            }
          });
          const duplicates = this.checkDuplicates(file['csvRecords'], file['missingHeaders']);
          file = { ...file, duplicates };
          file['allowMappingFields'] = !file['missingHeaders'].length && file['filename'] ? true : false;
        }
        file['uniqueValues'] = this.getGroupings(file['csvRecords']);
        this.file.emit(file);
      };

      reader.onerror = () => {
        alert('Unable to read ' + input.files[0]);
      };
    }

  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: string[], headers: string[], seperator: string): any[] {
    const records = [];
    csvRecordsArray.forEach((csvRecord, i) => {
      const data = csvRecord.split(seperator);
      if (!data[data.length - 1] && data.length > headers.length) {
        data.splice(data.length - 1, 1);
      }

      if (data.length === headers.length && i !== 0) {
        const dataItem = {};
        headers.forEach((header, id) => {
          dataItem[header] = data[id] ? data[id].trim() : null;
        });
        records.push(dataItem);
      }
    });
    return records;
  }

  getGroupings(records: any[]): any {
    const dist = {};
    records.forEach(record => {
      Object.keys(record).forEach(item => {
        if (!dist[item] && this.mandatoryHeaders.indexOf(item) === -1) {
          dist[item] = { open: false, headers: [] };
        }
        let found = false;
        if (dist[item]) {
          dist[item].headers.forEach(header => {
            found = header.name === record[item] ? true : found;
          });
          if (!found) {
            dist[item].headers.push({
              name: record[item],
              active: true
            });
          }
        }
      });
    });
    return dist;
  }

  getHeaderArray(csvRecordsArr: any, seperator: string): string[] {
    const headers = csvRecordsArr[0].split(seperator);
    const headerArray = [];
    headers.forEach(header => {
      if (header) {
        headerArray.push(header);
      }
    });
    return headerArray;
  }
}
