import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';

import { AuthenticationService } from '../authentication/authentication.service';
import { User, AppUser, UpdatePasswordRequest } from '../../models/user.model';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class UserService {

    token: any;
    url = '';
    timestamp: number;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('user');
        this.token = authenticationService.getAuthToken();
    }

    getUsers(): Observable<AppUser[]> {

        this.url = this.authenticationService.getWebserviceURL('user');
        this.token = this.authenticationService.getAuthToken();

        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting users from service : ' + this.url);
        return this.http.get(this.url, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getUsersByAccountId(id: any): Observable<AppUser[]> {

        this.url = this.authenticationService.getWebserviceURL('user');
        this.token = this.authenticationService.getAuthToken();

        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting users from service : ' + this.url);
        return this.http.get(this.url + 'Account/' + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getUserById(id: any): Observable<AppUser> {

        this.url = this.authenticationService.getWebserviceURL('user');
        this.token = this.authenticationService.getAuthToken();

        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting users from service');
        return this.http.get(this.url + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponseData(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getTokenForUser(id: any): Observable<any> {

        this.url = this.authenticationService.getWebserviceURL('user');
        this.token = this.authenticationService.getAuthToken();

        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiZGVtb0BmbGVldC1oZWFsdGguY29tIiwiQWNjb3VudElkIjoiNjQyNTVmODYtMWM4My00YjNhLTlmOWMtM2JiN2NhMjNjMGEyIiwiVXNlcklkIjoiZTBhNzgzNmUtYjdiMS00ZDg3LWFkMDItYTk0MzM5ZTI5NzFmIiwiUGVybWlzc2lvbnMiOiI1MDk3NiIsImV4cCI6MTU0ODE1ODk3NSwiaXNzIjoieW91cmRvbWFpbi5jb20iLCJhdWQiOiJ5b3VyZG9tYWluLmNvbSJ9.Re1NNdraDyWMMjyNITFELKKtCuQnvOxF5j0WUJ2dO_E';
        console.log('getting users from service');
        return this.http.get(this.url + 'impersonateToken/' + id, { headers: headers })
            .map((data) => {
                return data as any;
            })
            .catch(this.handleError);
    }


    resetPassword(id: any): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.post(this.url + 'reset/' + id, id, { headers: headers })
            .catch(this.handleError);
    }

    deleteUser(id: any): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.post(this.url + 'delete/' + id, id, { headers: headers })
            .catch(this.handleError);
    }

    updateUserProfile(appUser: AppUser): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.put(this.url, appUser, { headers: headers })
            .catch(this.handleError);
    }

    updateUserPassword(request: UpdatePasswordRequest): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });
        const urlExtra = 'Password'

        return this.http.put(this.url + urlExtra, request, { headers: headers })
            .catch(this.handleError);
    }


    saveUser(appUser: AppUser): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.post(this.url + 'Create', appUser, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponseData(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getUserRoleById(id: any): Observable<AppUser> {
        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting users from service');
        return this.http.get(this.url + 'UserRole/' + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponseData(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    parseResponse(json: any): AppUser[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Users.');

        const ident = 1;
        const users: AppUser[] = [];

        json.forEach(item => {
            let user = new AppUser();
            user = this.parseResponseData(item);
            users.push(user);
        });

        this.timestamp = new Date().getTime();
        return users;
    }

    parseResponseData(item: any): AppUser {
        const user = new AppUser();
        user.id = item.id;
        user.name = item.name;
        user.firstName = item.firstName ? item.firstName : '';
        user.lastName = item.lastName ? item.lastName : '';
        user.fullName = user.firstName + ' ' + user.lastName;
        user.language = item.language;
        user.timezoneIana = item.timezoneIana;
        user.accountId = item.accountId;
        user.accountName = item.accountName;
        user.userRoleId = item.userRoleId;
        user.userRoleName = item.userRoleName;
        user.whitelabel = item.whitelabel
        user.referenceId = item.referenceId;
        user.createdDate = item.createdDate;

        return user
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
