import { Component, OnInit } from '@angular/core';
import { detectBody } from '../../../app.helpers';
import { ColorService } from '../../../services/common/color.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

declare var jQuery: any;

@Component({
  selector: 'fh-basic',
  templateUrl: 'basicLayout.template.html',
  // tslint:disable-next-line:use-host-property-decorator
  host: { '(window:resize)': 'onResize()' }
})
export class BasicLayoutComponent implements OnInit {
  cssVariables: any;

  public ngOnInit(): any {
    detectBody();
    console.log('Init basiclayout');

    const whitelabel = this.authenticationService.getWhitelabel();
    this.colors.getThemeColors(whitelabel).subscribe(result => {
      console.log('Add theme');
      this.addTheme(result);
    });

    // this.eventService.onWhitelabelChance.subscribe(whitelabelChanged => {
    //   this.colors.getThemeColors(whitelabelChanged).subscribe(result => {
    //     console.log('Add theme');
    //     this.addTheme(result);
    //   });
    // });
  }

  constructor(private colors: ColorService, private authenticationService: AuthenticationService) {
  }

  addTheme(colors) {
    let css = '';

    css = '.nav-header, .navbar-static-top, #wrapper, .footer {background-color: ' + colors.primary + ' !important;  }';
    css += '.primary  { color: ' + colors.primary + '!important; } .secondary  { color: ' + colors.secondary + '!important; }';
    css += '.nav > li.active { border-left: 4px solid ' + colors.secondary + '!important; } .nav.nav-second-level > li.active { border-left: 0px solid ' + colors.secondary + '!important; }';
    css += '.btn-primary.btn-outline { color: ' + colors.secondary + '!important; background-color: #FFF!important; border-color: ' + colors.secondary + '!important;  } ';
    css += '.btn-primary { background-color: ' + colors.secondary + '!important; border-color: ' + colors.secondary + '!important; } ';
    css += '.seach-background { background-color: ' + colors.seachBackground + '!important } ';
    css += '.seach-background .form-control, .seach-background .form-control::placeholder  { color: ' + colors.seachColor + ' !important; } ';
    css += '.nav .open > a, .nav .open > a:hover, .nav .open > a:focus { background: ' + colors.secondary + ' !important; } ';
    css += '.label-primary, .badge-primary{ background-color: ' + colors.secondary + '!important; } ';
    css += '.cube1, .cube2, .rect1, .rect2, .rect3, .rect4, .rect5 , .sk-rect1, .sk-rect2, .sk-rect3, .sk-rect4, .sk-rect5{ background-color: ' + colors.secondary + '!important; } ';
    css += '.form-control:focus, .single-line:focus {  border-color: ' + colors.secondary + '!important;  } ';
    css += '.gray-bg { background-color: ' + colors.background + '!important;} ';
    css += '.progress-bar { background-color: ' + colors.secondary + ';  } ';
    css += 'a { color: #515151;  } a:hover { color: #515151;  }  ';
    css += '.toast-success { background-color: ' + colors.secondary + ';  } ';
    css += '.site_logo { width: 220px; height: 95px; background: url(data:image/png;base64,' + colors.logoBinary + ')!important;} ';
    css += '.pace .pace-progress { background: ' + colors.secondary + '!important; } ';
    css += '.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover { color: #fff; text-decoration: none; background-color: ' + colors.secondary + '!important;}';
    css += '.label-success, .badge-success { background-color: ' + colors.chartPositive + '!important; color: #FFFFFF;  }.label-danger, .badge-danger { background-color: ' + colors.chartNegative + '!important; color: #FFFFFF;} ';

    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    if (colors.overwrite === true) {
      head.appendChild(style);
    }
  }

  public onResize() {
    detectBody();
  }
}
