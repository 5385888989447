import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

// NGX-Bootstrap
import { BsDatepickerModule, ModalModule, TooltipModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap';

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimezonePickerModule } from 'ng2-timezone-selector';

// User Controlls
import { UserEditorComponent } from './usercontrols/userEditor.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { WizardModule } from '../../modules/wizard/wizard.module';
import { LangSelectorComponent } from './usercontrols/lang-selector.component';

// Pipes
import { OrderByPipe } from 'app/common/orderBy.pipe';
import { MomentModule } from 'angular2-moment';
import { DeviceEditorComponent } from './usercontrols/deviceEditor.component';

@NgModule({
  declarations: [
    UserEditorComponent,
    DeviceEditorComponent,
    LangSelectorComponent,
    OrderByPipe,
  ],
  imports: [
    FormsModule,
    MomentModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule,
    TimezonePickerModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    WizardModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(255,255,255,0.7)',
      backdropBorderRadius: '4px',
      primaryColour: '#DC8139',
      secondaryColour: '#DC8139',
      tertiaryColour: '#DC8139'
    }),
    TranslateModule
  ],
  exports: [
    OrderByPipe,
    UserEditorComponent,
    DeviceEditorComponent,
    LangSelectorComponent,
  ],
})

export class UsercontrolsModule { }
