import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fh-reporting',
  templateUrl: 'reporting.template.html'
})
export class ReportingComponent implements OnInit {
  leadingFile: any = {
    headers: [],
    data: []
  };
  newFile: any = {
    headers: [],
    data: [],
    name: ''
  };
  leadingHeader: string;
  newHeader: string;
  files: any[] = [];
  modalCombineOpen: boolean;
  modalSelectColumnsOpen: boolean;
  hideTable: boolean;
  combinedHeaders: string[] = [];
  file: any;

  constructor() { }

  ngOnInit(): void {
  }

  checkSeperator(text: string): string {
    let commas = 0;
    let semicolons = 0;
    for (let i = 0; i < text.length; i++) {
      if (text[i] === ',') {
        commas += 1;
      } else if (text[i] === ';') {
        semicolons += 1;
      }
    }
    return commas > semicolons ? ',' : ';';
  }

  closeModal(): void {
    this.file = null;
    if (this.modalSelectColumnsOpen) {
      this.combinedHeaders.splice(this.combinedHeaders.length - 1 , 1);
    }
    this.modalCombineOpen = false;
    this.modalSelectColumnsOpen = false;
    this.files.splice(this.files.length - 1 , 1);
  }

  combineFiles(headers: string[]): void {
    this.newHeader = headers[0];
    this.leadingHeader = headers[1];
    this.combinedHeaders.push(this.leadingHeader);
    this.newFile.combinedHeader = this.newHeader;
    this.modalCombineOpen = false;
    this.modalSelectColumnsOpen = true;
  }

  fileChangeListener($event: any): void {
    if ($event.srcElement.files.length) {
      const reader = new FileReader();
      reader.readAsText($event.target.files[0]);

      reader.onload = (data) => {
        const file = {};
        const csvRecordsArray = (<string>reader.result).split(/\r\n|\n/);
        const seperator = this.checkSeperator(csvRecordsArray[0]);
        file['name'] = $event.srcElement.files.length ? $event.srcElement.files[0].name : null;
        file['headers'] = this.getHeaderArray(csvRecordsArray, seperator);
        file['data'] = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, file['headers'], seperator);
        this.newFile = JSON.parse(JSON.stringify(file));
        if (!this.files.length) {
          this.modalSelectColumnsOpen = true;
        } else {
          this.leadingHeader = this.leadingFile.headers[0];
          this.newHeader = this.newFile.headers[0];
          this.modalCombineOpen = true;
        }
        this.files.push(file);
      };

      reader.onerror = () => {
        alert('Unable to read ' + $event.target.files[0]);
      };
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: string[], headers: string[], seperator: string): any[] {
    const dataArr = [];
    csvRecordsArray.forEach((csvRecord, i) => {
      const data = csvRecord.split(seperator);
      if (data.indexOf('') !== -1) {
        data.splice(data.indexOf(''), 1);
      }

      if (data.length === headers.length && i !== 0) {
        const dataItem = {};
        headers.forEach((header, id) => {
          dataItem[header] = data[id] ? data[id].trim() : null;
        });
        dataArr.push(dataItem);
      }
    });
    return dataArr;
  }

  getHeaderArray(csvRecordsArr: any, seperator: string): string[] {
    const headers = csvRecordsArr[0].split(seperator);
    const headerArray = [];
    headers.forEach(header => {
      if (header) {
        headerArray.push(header);
      }
    });
    return headerArray;
  }

  removeFile(index: number): void {
    this.files[index].leadingHeaders.forEach((removingHeader: string, i: number) => {
      if (this.files[index].selectedHeaders[i] && this.combinedHeaders.indexOf(removingHeader) === -1 && (!this.files[index].combinedHeader || this.files[index].combinedHeader !== removingHeader)) {
        this.leadingFile.headers.splice(this.leadingFile.headers.indexOf(removingHeader), 1);
        this.leadingFile.data.forEach((row: any) => {
          delete row[removingHeader];
        });
      }
    });
    this.files.splice(index, 1);
    if (!this.files.length) {
      this.leadingFile = {
        data: [],
        headers: []
      };
    }
  }

  selectColumns($event: any): void {
    this.files.forEach(file => {
      if (file.name === this.newFile.name) {
        file['selectedHeaders'] = this.newFile.selectedHeaders;
        file['leadingHeaders'] = this.newFile.leadingHeaders;
        file['combinedHeader'] = this.newFile.combinedHeader;
      }
    });
    this.hideTable = true;
    if (this.leadingHeader && this.newHeader) {
      this.leadingFile.data.forEach(leadingRow => {
        this.newFile.data.forEach(newRow => {
          if (leadingRow[this.leadingHeader] === newRow[this.newHeader]) {
            $event.forEach(column => {
              if (column.name !== this.newHeader) {
                leadingRow[column.name] = newRow[column.key];
                if (this.leadingFile.headers.indexOf(column.name) === -1) {
                  this.leadingFile.headers.push(column.name);
                }
              }
            });
          }
        });
      });
    } else {
      this.newFile.data.forEach(row => {
        const dataItem = {};
        $event.forEach(column => {
          dataItem[column.name] = row[column.key];
          if (this.leadingFile.headers.indexOf(column.name) === -1) {
            this.leadingFile.headers.push(column.name);
          }
        });
        this.leadingFile.data.push(dataItem);
      });
    }
    this.modalSelectColumnsOpen = false;
    this.file = null;
    setTimeout(() => {
      this.hideTable = false;
    }, 1);
  }
}
