import { Component, Renderer2 } from '@angular/core';
import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { TranslateService } from '@ngx-translate/core';

// Models
import { AuthenticationService } from './services/authentication/authentication.service';
import { BsLocaleService, defineLocale } from 'ngx-bootstrap';
import { deLocale, enGbLocale, nlLocale, arLocale, ruLocale, esLocale, frLocale, zhCnLocale,  } from 'ngx-bootstrap/locale';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
window['moment'] = Moment;
mTZ()

defineLocale('nl', nlLocale);
defineLocale('de', deLocale);
// defineLocale('en', ); // when undefined it takes default
defineLocale('gb', enGbLocale);
defineLocale('ug', enGbLocale);
defineLocale('ar', arLocale);
defineLocale('ru', ruLocale);
defineLocale('es', esLocale);
defineLocale('fr', frLocale);
defineLocale('cn', zhCnLocale);

@Component({
  selector: 'fh-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  constructor(private localeService: BsLocaleService, private renderer: Renderer2, translate: TranslateService, private authenticationService: AuthenticationService) {
    translate.setDefaultLang('lt');

    if (!translate.currentLang) {
      let currentSavedLang = this.authenticationService.getLanguage();
      if (currentSavedLang === '' || currentSavedLang == null) {
        currentSavedLang = 'en';
      }
      console.log('No currentlang... setting : ' + currentSavedLang);
      translate.use(currentSavedLang);
      Moment.locale(currentSavedLang);
      this.localeService.use(currentSavedLang);

      translate.onLangChange.subscribe((event) => {
        Moment.locale(event.lang);
        this.localeService.use(event.lang);
        if (event.lang === 'ur' || event.lang === 'ar') {
          this.renderer.addClass(document.body, 'rtls');
        } else {
          this.renderer.removeClass(document.body, 'rtls');
        }
      });
    }
  }

  ngOnInit(): void {

    // General load
  }
}
