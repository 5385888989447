import { Component } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'fh-blank',
  templateUrl: 'blankLayout.template.html'
})
export class BlankLayoutComponent {

}
